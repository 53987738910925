<template>
  <div style="background-color: #f8f8f8">
    <!-- Tampilan Navbar -->
    <navigation />
    <div class="contoh">
      <div
        style="padding: 5%; background: var(--color-neutral-neutral-00, #fff)"
        class="row"
      >
        <p class="text-base mt-[40px] mb-[100px]">
          <h3 style="color: black;" >Privacy policy</h3>
          <br /><br /><br />
          <span
            >In Build your Dreams BYD INDONESIA (hereinafter BYD or "we", "our",
            or "us"), with address at Jl. Jend. Sudirman Kav. 29-31, WTC Tower 3
            Suite 3-19, South Jakarta, DKI Jakarta 12910, ID; in our role as
            Data Controller, in compliance with the Applicable Law and other
            regulations relating to the protection of personal data, hereby
            informs by means of this Privacy Notice (hereinafter "the Notice").
            We inform you (in your role as Personal Data Controller) that the
            personal information of all our clients, potential clients and users
            of our websites is treated in a strictly confidential manner, so you
            can feel completely safe and that when acquiring our goods and/or
            services, we make a permanent effort to safeguard and protect it.<br /><br />
            This Privacy Policy does not apply to our authorized retailers, or
            third parties to whom you directly provide your information
            including but not limited to, subscription services, insurance
            companies, or other third parties. These entities are independent of
            us and responsible for their own collection of information. Please
            refer directly to those entities and their privacy policies for more
            information.</span
          >
          <span></span> <br /><br />
          <b
            >I. PERSONAL DATA WE COLLECT AND WHY</b
          >
          <br /><br />
          <span
            >"Personal data" refers to any data or information from which an individual may be directly or indirectly identified ("Personal Data"). When we provide our website, products, or services, we have a need to process Personal Data. When you access and use our Website, we collect the following Personal Data to provide our services to you:</span
          >
          <br /><br />
          <span
            ><b class="font-semibold">Access and Use our Website:</b> We collect
            your following Personal Data to provide our website to you:
            <br /><br />(a) your Internet Protocol (IP) address, <br />(b) your
            location information (such as may be determined from your Internet
            Protocol (IP) address), <br />(c) your internet activity information
            (such as usage information), <br />(d) device information (such as
            hardware model, operating system, browser, and your Website
            preferences) and the time that your device visited our Website. We
            may rely on various legal grounds to collect, use, and otherwise
            process your Personal Data, including: your consent; that the
            Personal Data is necessary for providing our website to you; to
            comply with a legal obligation; to protect your, or someone else's
            vital interests; or for a legitimate interest that is balanced
            against your rights and interests.</span
          >
          <span></span> <br /><br />
          <b 
            >II. HOW WE SHARE YOUR PERSONAL DATA</b
          >
          <br /><br />
          <span
            >We share the Personal Data mentioned above within the Jaja Auto family of companies, which includes parents, corporate affiliates, subsidiaries, business units, and other companies with common ownership. We may also use or share your Personal Data in a de-identified or aggregate manner.
</span
          >
          <span></span> <br /><br />
          <b>III. HOW TO MAKE REQUESTS</b>
          <br /><br />
          <span
            >If you would like to access, correct, or delete any Personal Data we hold about you, or exercise any other data subject rights available under applicable laws or regulations for protecting Personal Data ("Applicable Data Protection Law"), you may submit a request via email at jajaauto@gmail.com.
</span
          >
          <span></span> <br /><br />
          <b>IV. RETENTION PERIOD</b> <br /><br />
          <span
            >We will not use or store Personal Data longer than necessary to fulfill the purposes mentioned above and will remove collected Personal Data after the required period to achieve the purposes described in this Privacy Policy has passed or as required by Applicable Data Protection Law.
</span
          >
          <span></span> <br /><br />
          <b
            >V. COOKIES AND OTHER TRACKING TECHNOLOGIES</b
          >
          <br /><br />
          <span>
            Our websites collect information via cookies, web beacons, pixels, tags, or other tracking technologies, such as your Internet Service Provider and Internet Protocol (IP) address, the date and time of website access, the pages visited, and the IP address from which the website was accessed. Some cookies exist only during a single session, while others persist over multiple sessions. We use these technologies to remember user preferences, enhance website performance, provide relevant offers, measure email campaign effectiveness, and personalize online content.

          </span>
          <br /><br />
          <span>
            You may refuse or delete cookies, but this may impair some website functionality. If you previously accepted cookies and directed us to share your information on our sites, you can change your cookie preference by clicking on the cookie settings link in the footer of the site. Please refer to your browser's Help instructions to learn more about managing cookies and other tracking technologies.

          </span>
          <span></span> <br /><br />
          <b>VI. SECURITY</b> <br /><br />
          <span>
            We implement technical and organizational security measures to protect your Personal Data from tampering, loss, destruction, or unauthorized access. We continuously improve our security measures in line with technological advancements.

          </span>
          <span></span> <br /><br />
          <b
            >VII. OUR COMMITMENT TO CHILDREN'S PRIVACY</b
          >
          <br /><br />
          <span>
            We do not knowingly collect, use, share, or sell the Personal Data of children. If we learn that we have collected Personal Data from a child, we will delete that information. We kindly request that individuals under the legal age refrain from providing us with personal data.

          </span>
          <span></span> <br /><br />
          <b>VIII. INTERNATIONAL TRANSFER</b>
          <br /><br />
          <span>
            As a global company operating internationally, your Personal Data may be transferred to and stored at our data centers in Germany and the United States. We comply with Applicable Data Protection Law on cross-border data transfers to ensure your Personal Data is protected as described in this Privacy Policy, regardless of its location.
          </span>
          <br /><br />
          <span>
            We may rely on various legal grounds to collect, use, and otherwise process your information, including your consent; necessity for providing our services; compliance with legal obligations; protection of vital interests; or pursuit of legitimate interests balanced against your rights and interests.
          </span>
          <span></span> <br /><br />
          <b>IX.- CHANGES TO THIS PRIVACY POLICY</b>
          <br /><br />
          <span>
            We reserve the right to amend this Privacy Policy at our discretion
            and at any time. If the changes allow us to use Personal Data in
            ways that materially affect your rights, we will post a special note
            and, as it is required by law, will prompt you for your consent for
            certain changes. When we make changes this Privacy Policy, we will
            post the updated Privacy Policy and update the “Last Updated” date.
            Each version of this Privacy Policy will be identified by their Last
            Updated date.
          </span>
          <span></span> <br /><br />
          <b>X.- CONTACT US</b> <br /><br />
          <span>
            If you have any questions or comments about this Privacy Policy,
            please do not hesitate to contact us at:
            <br />
            E-mail: jajaauto@gmail.com
            <br />
            Address: Jl. H. Baping No.100, Ciracas, Jakarta Timur
          </span>
        </p>
      </div>
    </div>
    <foote />
  </div>
</template>
    <script>
import navigation from "../components/backup";
import foote from "../components/Footer";

export default {
  name: "testDrive",
  components: {
    navigation, //Navbar
    foote, // Footer
  },
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {},
};
</script>
       
        <style scoped>
.contoh {
  padding-top: 7%;
}
</style>
        