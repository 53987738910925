<template>
  <div
    class="d-none d-lg-block"
    style="padding-top: -5%; background-color: #f9f9f9"
    ref="resultsContainer"
  >
    <div class="car-area bg py-60">
      <div>
        <div class="d-flex justify-content-center">
          <span
            style="
              color: var(--Color-Blue-Blue-05, #207ace);
              font-family: Poppins;
              font-size: 48px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            "
          >
            Mobil
            <span
              style="
                color: var(--Color-Neutral-Neutral-07, #504e4e);
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                font-family: Poppins;
              "
              >Listrik</span
            >
          </span>
        </div>
        <!-- Tampilan Produk Populer Listing -->
        <div class="container">
          <div class="test">
            <v-slide-group show-arrows center-active>
              <template v-slot:next>
                <v-icon color="orange" large>mdi-chevron-right</v-icon>
              </template>
              <template v-slot:prev>
                <v-icon color="orange" large>mdi-chevron-left</v-icon>
              </template>
              <v-slide-item
                v-for="c in carListrik.slice(0, limit)"
                :key="c.id"
                class="mr-5 custom-slide-item"
              >
                <!-- Tampilan data mobil -->
                <div
                  data-wow-delay=".25s"
                  style="
                    box-shadow: 20px;
                    border-radius: 10px;
                    padding: 10px;
                    height: auto;
                    margin-bottom: 25px;
                    margin-top: 25px;
                    transition: var(--transition);
                  "
                >
                  <div>
                    <center>
                      <img
                        :src="c.imagePath"
                        style="width: 100%;"
                        alt=""
                        @error="handleImageError"
                      />
                    </center>
                  </div>
                  <center>
                    <div>
                      <div>
                        <h4>
                          <a
                            style="
                              color: black;
                              display: inline-block;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: nowrap;
                              max-width: 100%;
                              font-weight: 500;
                              font-family: Poppins;
                            "
                            href="#"
                            >{{ c.name }}</a
                          >
                        </h4>
                        <span style="color: #207ace; font-family: Poppins"
                          >Rp {{ formatPrice(c.rangeHarga) }}</span
                        >
                      </div>
                      <v-chip
                        class="mt-2"
                        closable
                        style="padding: 5px"
                        color="#207ACE"
                        text-color="#207ACE"
                        label
                      >
                        <span
                          style="
                            color: white;
                            font-weight: 500;
                            font-size: 14px;
                            font-family: Poppins;
                          "
                          >{{ c.transmisi }} | {{ c.bbm }}</span
                        ></v-chip
                      >
                      <button @click="handledetaillates(c.slug)"
                        style="font-family: Poppins"
                      >
                        Detail Mobil
                      </button>
                    </div>
                  </center>
                </div>
              </v-slide-item>
            </v-slide-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      carListrik: [],
    };
  },
  mounted() {
    this.fetchListrikCars();
  },
  methods: {
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("id-ID");
      return formatter.format(price);
    },
    fetchListrikCars() {
      axios
        .get("https://api.jaja.id/jauto/produk/get_listrik", {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
          },
        })
        .then((response) => {
          this.carListrik = response.data.data;
        })
        .catch((error) => {
          console.error("Failed to fetch popular cars data:", error);
        });
    },
    selectCar(index) {
      this.selectedCarIndex = index;
    },
  },
};
</script>

<style>
.custom-slide-item {
  flex: 1; /* Atur lebar setiap item */
}
</style>
