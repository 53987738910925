<template>
  <div class="home-3">
    <!-- Start Navbar -->
    <navigation />
    <!-- End Navbar -->

    <div ref="carSingleWrapper" class="car-item-single bg">
      <div class="container">
        <div class="contoh-elemen">
          <div class="car-single-wrapper">
            <div class="row">
              <!-- Start column gambar -->
              <div class="col-lg-8">
                <div class="car-single-details">
                  <!-- Start Tampilan Image dan judul produk -->
                  <div class="car-single-widget">
                    <div class="car-single-top">
                      <v-chip
                        class="ma-2"
                        closable
                        color="#DEF3FF"
                        text-color="#207ACE"
                        label
                        style="font-family: Poppins"
                      >
                        {{ carDetail[0].merek }}
                      </v-chip>
                      <router-link :to="'/'">
                        <img
                          class="d-lg-none mt-1"
                          src="../assets/img/homepage/batch.png"
                          style="width: 100px; height: 31px"
                          alt=""
                      /></router-link>
                      <div class="judul">
                        <h3
                          style="color: black; font-family: Poppins"
                          class="car-single-title"
                        >
                          {{ carDetail[0].name }}
                        </h3>
                      </div>

                      <div
                        style="font-size: 16px; font-family: Poppins"
                        class="d-none d-lg-block car-single-meta"
                      >
                        <li>
                          <i class="far fa-clock"></i> Dibuat:
                          {{ carDetail[0].date_created }}
                        </li>
                        <li>
                          <i class="far fa-eye"></i> Dilihat:
                          {{ carDetail[0].viewed }}
                        </li>

                        <div class="share-buttons">
                          <!-- Tombol Utama Share -->
                          <button
                            @click="toggleShareOptions"
                            class="main-share-btn"
                          >
                            <i class="fas fa-share"></i>
                          </button>

                          <!-- Pilihan Share yang Muncul Setelah Tombol Utama Diklik -->
                          <div v-if="showShareOptions" class="share-options">
                            <!-- Tombol Share untuk WhatsApp -->
                            <button @click="shareWhatsApp" class="whatsapp-btn">
                              <i class="fab fa-whatsapp"></i>
                            </button>

                            <!-- Tombol Share untuk Facebook -->
                            <button @click="shareFacebook" class="facebook-btn">
                              <i class="fab fa-facebook"></i>
                            </button>

                            <!-- Tombol Copy Link -->
                            <button @click="copyLink" class="copy-link-btn">
                              <i class="fas fa-copy"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="car-single-slider">
                      <div class="item-gallery">
                        <div class="flexslider-thumbnails">
                          <div class="" data-flickity>
                            <div class="carousel">
                              <div class="carousel-cell">
                                <!-- responsive -->
                                <center>
                                  <!-- <img
                                    :src="currentImagePath"
                                    style="height: auto; image-fit: cover"
                                    alt="Selected Image"
                                  /> -->
                                  <div>
                                    <vue-pinch-zoom
                                      :options="pinchZoomOptions"
                                      style="background-color: white"
                                    >
                                      <img
                                        :src="currentImagePath"
                                        style="max-width: 100%"
                                      />
                                    </vue-pinch-zoom>

                                    <div
                                      v-if="isLoading"
                                      class="loading-overlay"
                                    >
                                      <img
                                        style="width: 100px; height: 100px"
                                        src="../assets/img/detail/ld.gif"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </center>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End Tampilan Image dan judul produk -->

                  <!-- Start Tampilan Spesifikasi dan Deskripsi Produk -->
                  <div
                    class="car-single-widget d-none d-md-none d-lg-block d-xl-block"
                  >
                    <h4 class="mb-4">Spesifikasi</h4>
                    <hr />
                    <div class="car-key-info">
                      <div class="row">
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-settings"></i>
                            </div>
                            <div class="car-key-content">
                              <span style="font-family: Poppins"
                                >Tipe Mobil</span
                              >
                              <h6
                                style="
                                  color: black;
                                  font-weight: 400;
                                  font-family: Poppins;
                                "
                              >
                                {{ type }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-speedometer"></i>
                            </div>
                            <div class="car-key-content">
                              <span style="font-family: Poppins">Kategori</span>
                              <h6 style="color: black; font-weight: 400">
                                {{ carDetail[0].jenis_name }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-gas-station"></i>
                            </div>
                            <div class="car-key-content">
                              <span style="font-family: Poppins"
                                >Bahan Bakar</span
                              >
                              <h6 style="color: black; font-weight: 400">
                                {{ listrikNonListrik }}/ {{ bbm }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-drive"></i>
                            </div>
                            <div class="car-key-content" ref="resultsContainer">
                              <span style="font-family: Poppins">Kursi</span>
                              <h6 style="color: black; font-weight: 400">
                                {{ seat }}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-drive"></i>
                            </div>
                            <div class="car-key-content" ref="resultsContainer">
                              <span style="font-family: Poppins">CC</span>
                              <h6 style="color: black; font-weight: 400">
                                {{ spesifikasi }}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />

                    <h4 class="mb-3">Deskripsi</h4>
                    <hr />
                    <div class="mb-4" v-if="selectedType">
                      <!-- <div v-html="carDetail[0].deskripsi"></div> -->
                      <!-- <span>{{carDetail[0].deskripsi}}</span> -->
                      <div>
                        <div
                          v-html="truncateText(carDetail[0].deskripsi)"
                          style="white-space: pre-line; font-family: Poppins"
                        ></div>
                        <span
                          v-if="isTextTooLong && !showFullText"
                          @click="showFullText = true"
                          style="cursor: pointer; color: blue"
                          >... Baca Selengkapnya</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="car-single-widget d-md-block d-lg-none d-xl-none">
                    <!-- Start Tampilan Harga Produk -->
                    <div class="hargaotr">
                      <h6 class="car-single-price">Harga OTR</h6>
                      <span
                        class="mt-5"
                        style="
                          color: #1f1c1c;
                          font-size: 34px;
                          font-weight: bold;
                          padding: 0px 10px 0px 0px;
                          font-family: Poppins;
                        "
                        v-if="selectedType"
                      >
                        Rp {{ formatPrice(harga) }}
                      </span>
                    </div>
                    <!-- End Tampilan Harga Produk -->

                    <!-- Start Tampilan Warna Mobil -->

                    <div class="form-group mt-5">
                      <h6 class="car-single-price">
                        Warna
                        <span
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            color: gray;
                            font-family: Poppins;
                          "
                          >{{ getSelectedColorName(selectedColor) }}</span
                        >
                      </h6>
                      <v-card flat>
                        <v-radio-group v-model="selectedColor" inline>
                          <v-row>
                            <v-col
                              v-for="(image, index) in carWarna"
                              :key="index"
                              cols="2"
                            >
                              <div class="chip-container">
                                <div
                                  :style="{
                                    backgroundColor: image.hexColorCode,
                                    color: 'white',
                                    border: '2px solid #4F4E4E',
                                    outline:
                                      selectedChipIndex === index
                                        ? '3px solid #207ACE'
                                        : 'none',
                                    outlineOffset: '-2px',
                                    width: '30px',
                                    height: '30px',
                                    borderRadius: '10%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '12px',
                                  }"
                                  @click="
                                    changeImage(
                                      image.imagePath,
                                      image.id,
                                      index
                                    )
                                  "
                                ></div>
                              </div>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </v-card>
                    </div>
                    <!-- <div class="col">
                        <div v-if="carSeat.length > 0" class="form-group mt-5">
                          <h6 class="car-single-price">Interior</h6>

                          <v-card flat>
                            <v-radio-group v-model="selectedSeat" inline>
                              <v-row>
                                <v-col
                                  v-for="(seat, index) in carSeat"
                                  :key="index"
                                  cols="2"
                                >
                                  <div class="chip-container">
                                    <div
                                      :style="{
                                        backgroundColor: seat.hexColorCode,
                                        color: 'white',
                                        border: '2px solid #4F4E4E',
                                        outline:
                                          selectedSeatIndex === index
                                            ? '3px solid #207ACE'
                                            : 'none',
                                        outlineOffset: '-2px',
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '10%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '12px',
                                      }"
                                      @click="
                                        changeSeatImage(
                                          seat.imagePath,
                                          seat.id,
                                          index
                                        )
                                      "
                                    ></div>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-radio-group>
                          </v-card>
                        </div>
                      </div> -->

                    <!-- Fungsi untuk Pilihan Velg Mobil Lengkap -->
                    <!-- <div v-if="carVelg.length > 0" class="form-group mt-5">
                      <h6 class="car-single-price">Velg</h6>
                      <v-card flat>
                        <v-radio-group v-model="selectedVelg" inline>
                          <v-row>
                            <v-col
                              v-for="(velg, index) in filteredCarVelg"
                              :key="index"
                              cols="2"
                            >
                              <div
                                @click="
                                  changeVelgImage(
                                    velg.imagePath,
                                    velg.id,
                                    index
                                  )
                                "
                                :class="{
                                  'selected-option':
                                    index === selectedVelgIndex,
                                }"
                              >
                                <img
                                  :src="velg.colorName"
                                  style="
                                    width: 120px;
                                    height: auto;
                                    border-radius: 50%;
                                  "
                                  alt="Velg Image"
                                />
                                <v-icon
                                  v-if="
                                    index === selectedVelgIndex && bothSelected
                                  "
                                  class="check-icon"
                                  style="
                                    float: right;
                                    color: #207ACE;
                                    font-size: 18px;
                                  "
                                >
                                  mdi mdi-checkbox-marked-circle-outline
                                </v-icon>
                              </div>
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </v-card>
                    </div> -->
                    <br />
                    <div class="mt-5">
                      <div>
                        <h4 class="mb-4" style="color: black">Spesifikasi</h4>
                        <hr />
                        <div class="car-key-info">
                          <div class="row">
                            <div class="col-lg-3 col-md-4 col-6">
                              <div class="car-key-item">
                                <div class="car-key-icon">
                                  <i class="flaticon-settings"></i>
                                </div>
                                <div class="car-key-content">
                                  <span>Body Type</span>
                                  <h6 style="color: black; font-weight: 400">
                                    {{ type }}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-6">
                              <div class="car-key-item">
                                <div class="car-key-icon">
                                  <i class="flaticon-speedometer"></i>
                                </div>
                                <div class="car-key-content">
                                  <span>Kategori</span>
                                  <h6 style="color: black; font-weight: 400">
                                    {{ carDetail[0].jenis_name }}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-6">
                              <div class="car-key-item">
                                <div class="car-key-icon">
                                  <i class="flaticon-gas-station"></i>
                                </div>
                                <div class="car-key-content">
                                  <span>Mileage</span>
                                  <h6 style="color: black; font-weight: 400">
                                    {{ listrikNonListrik }}/ {{ bbm }}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-6">
                              <div class="car-key-item">
                                <div class="car-key-icon">
                                  <i class="flaticon-drive"></i>
                                </div>
                                <div
                                  class="car-key-content"
                                  ref="resultsContainer"
                                >
                                  <span>Seat</span>
                                  <h6 style="color: black; font-weight: 400">
                                    {{ seat }}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-6">
                              <div class="car-key-item">
                                <div class="car-key-icon">
                                  <i class="flaticon-drive"></i>
                                </div>
                                <div
                                  class="car-key-content"
                                  ref="resultsContainer"
                                >
                                  <span>CC</span>
                                  <h6 style="color: black; font-weight: 400">
                                    {{ spesifikasi }}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br />

                      <div>
                        <h4 class="mb-3" style="color: black">Deskripsi</h4>
                        <hr />
                        <div class="mb-4" v-if="selectedType">
                          <!-- <div v-html="carDetail[0].deskripsi"></div> -->
                          <!-- <span>{{carDetail[0].deskripsi}}</span> -->
                          <div>
                            <div
                              v-html="truncateText(carDetail[0].deskripsi)"
                              style="white-space: pre-line"
                            ></div>
                            <span
                              v-if="isTextTooLong && !showFullText"
                              @click="showFullText = true"
                              style="cursor: pointer; color: blue"
                              >... Baca Selengkapnya</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <!-- End Tampilan Warna Mobil -->

                    <!-- Tampilan Tabs Angsuran dan Sewa -->
                    <v-tabs
                      v-model="activeTab"
                      fixed-tabs
                      bg-color=""
                      color="deep-purple-accent-4"
                      align-tabs="center"
                      style="color: #0c5eaa"
                    >
                      <v-tab style="color: #0c5eaa; font-family: Poppins">
                        <b>Angsuran</b>
                      </v-tab>
                      <v-tab style="color: #0c5eaa; font-family: Poppins"
                        ><b>Tunai</b>
                      </v-tab>

                      <!-- Start Tampilan Angsuran -->
                      <v-tab-item>
                        <v-card>
                          <v-card-text>
                            <div class="form-group mt-5">
                              <div class="form-group mt-5">
                                <!-- Start Tampilan Tipe, DP, MITRA, WILAYAH -->
                                <div class="tipe">
                                  <v-row class="mt-5">
                                    <v-col cols="12">
                                      <h6 class="car-single-price">
                                        Tipe Mobil
                                      </h6>
                                      <div class="custom-select-wrapper">
                                        <select
                                          class="nice-select"
                                          style="width: 100%"
                                          v-model="selectedType"
                                          @change="updateHarga"
                                        >
                                          <!-- <option value="">Pilihan Tipe</option> -->
                                          <option
                                            v-for="grade in carType"
                                            :key="grade.id"
                                            :value="grade.type"
                                          >
                                            {{ grade.type }}
                                          </option>
                                        </select>

                                        <div class="custom-select-arrow"></div>
                                      </div>
                                    </v-col>
                                  </v-row>

                                  <v-row class="mt-5">
                                    <v-col cols="8">
                                      <h6 class="car-single-price">DP (Rp)</h6>
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <span
                                            class="input-group-text"
                                            style="
                                              height: 50px;
                                              border: 1px solid black;
                                            "
                                            id="basic-addon1"
                                            >Rp</span
                                          >
                                        </div>
                                        <input
                                          type="text"
                                          v-model="dp"
                                          @input="handleRpInput"
                                          class="form-control nice-input"
                                          placeholder="Masukkan dalam Rp"
                                          aria-describedby="basic-addon1"
                                        />
                                      </div>
                                    </v-col>
                                    <v-col cols="4">
                                      <h6 class="car-single-price">%</h6>
                                      <div class="input-group">
                                        <input
                                          v-model="uangMukaPersentase"
                                          @input="handlePersentaseInput"
                                          type="text"
                                          class="form-control nice-input"
                                          aria-label="Amount (to the nearest dollar)"
                                        />
                                        <div class="input-group-append">
                                          <span
                                            style="
                                              height: 50px;
                                              border: 1px solid black;
                                            "
                                            class="input-group-text"
                                            >%</span
                                          >
                                        </div>
                                      </div>
                                    </v-col>
                                  </v-row>
                                  <!-- 
                                  <v-row class="mt-5">
                                    <v-col cols="12">
                                      <h6 class="car-single-price">Mitra</h6>
                                      <div class="custom-select-wrapper">
                                        <select
                                          class="nice-select"
                                          v-model="selectedMitra"
                                          @change="fetchData"
                                        >
                                          <option
                                            v-for="bank in banks"
                                            :key="bank.id_bank"
                                            :value="bank.id_bank"
                                          >
                                            {{ bank.nama_bank }}
                                          </option>
                                        </select>
                                        <div class="custom-select-arrow"></div>
                                      </div>
                                    </v-col>
                                  </v-row> -->

                                  <v-row class="mt-5">
                                    <v-col cols="12">
                                      <h6 class="car-single-price">Wilayah</h6>
                                      <div class="custom-select-wrapper">
                                        <select
                                          class="nice-select"
                                          v-model="selectedWilayah"
                                          @change="fetchData"
                                        >
                                          <option
                                            v-for="area in wilayah"
                                            :key="area.id_wilayah"
                                            :value="area.id_wilayah"
                                          >
                                            {{ area.nama_wilayah }}
                                          </option>
                                        </select>
                                        <div class="custom-select-arrow"></div>
                                      </div>
                                    </v-col>
                                  </v-row>
                                </div>
                                <!-- End Tampilan Tipe, DP, MITRA, WILAYAH -->

                                <!-- Start Tampilan Button Hitung Angsuran -->
                                <v-row class="mt-5">
                                  <v-col cols="12">
                                    <div>
                                      <button
                                        @click="fetchSimulasi"
                                        type="submit"
                                        style="font-family: Poppins"
                                        class="button-angsuran"
                                      >
                                        <span v-if="isLoading">
                                          <i class="fas fa-spinner fa-spin"></i>
                                          Loading...
                                        </span>
                                        <span v-else>Hitung Angsuran</span>
                                      </button>
                                      <button
                                        v-if="carDetail[0].id === '77'"
                                        type="submit"
                                        class="button-ta"
                                        @click="bookTestDrive"
                                      >
                                        Ajukan Test Drive
                                      </button>
                                    </div>
                                  </v-col>
                                </v-row>
                                <!-- End Tampilan Button Hitung Angsuran -->
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <!-- End Tampilan Angsuran -->

                      <!-- Start Tampilan Sewa -->
                      <!-- <v-tab-item>
                      <v-card>
                        <v-card-text>
                          <div class="form-group mt-5">
                            <div class="form-group mt-5">
                              <div class="tipe">
                                <v-row class="mt-5">
                                  <v-col cols="12">
                                    <h6 class="car-single-price">Tipe Mobil</h6>
                                    <div class="custom-select-wrapper">
                                      <select
                                        class="nice-select"
                                        style="width: 100%"
                                        v-model="selectedType"
                                        @change="updateHarga"
                                      >
                                        <option value="">Pilihan Tipe</option>
                                        <option
                                          v-for="grade in carType"
                                          :key="grade.id"
                                          :value="grade.type"
                                        >
                                          {{ grade.type }}
                                        </option>
                                      </select>

                                      <div class="custom-select-arrow"></div>
                                    </div>
                                  </v-col>
                                </v-row>

                                Elemen HTML untuk Menampilkan Hasil Simulasi
                              </div>
                              <v-row class="mt-5">
                                <v-col cols="12">
                                  <div>
                                    <button
                                      type="submit"
                                      @click="klikButton"
                                      class="button-angsuran"
                                    >
                                      Ajukan Tunai
                                    </button>
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-tab-item> -->
                      <v-tab-item>
                        <v-card>
                          <v-card-text>
                            <div class="form-group mt-5">
                              <div class="form-group mt-5">
                                <div class="tipe">
                                  <v-row class="mt-5">
                                    <v-col cols="12">
                                      <h6 class="car-single-price">
                                        Tipe Mobil
                                      </h6>
                                      <div class="custom-select-wrapper">
                                        <select
                                          class="nice-select"
                                          style="width: 100%"
                                          v-model="selectedType"
                                          @change="updateHarga"
                                        >
                                          <!-- <option value="">Pilihan Tipe</option> -->
                                          <option
                                            v-for="grade in carType"
                                            :key="grade.id"
                                            :value="grade.type"
                                          >
                                            {{ grade.type }}
                                          </option>
                                        </select>

                                        <div class="custom-select-arrow"></div>
                                      </div>
                                    </v-col>
                                  </v-row>

                                  <!-- Elemen HTML untuk Menampilkan Hasil Simulasi -->
                                </div>
                                <v-row class="mt-5">
                                  <v-col cols="12">
                                    <div>
                                      <button
                                        type="submit"
                                        class="button-angsuran"
                                        @click="submitTunai"
                                      >
                                        Ajukan Tunai
                                      </button>
                                      <button
                                        v-if="carDetail[0].id === '77'"
                                        type="submit"
                                        class="button-ta"
                                        @click="bookTestDrive"
                                      >
                                        Ajukan Test Drive
                                      </button>
                                    </div>
                                  </v-col>
                                </v-row>
                              </div>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <!-- End Tampilan Sewa -->
                    </v-tabs>
                  </div>
                </div>
              </div>
              <!-- End column gambar -->

              <!-- Start column Harga -->
              <div class="col-lg-4">
                <div
                  class="car-single-widget d-none d-md-none d-lg-block d-xl-block"
                >
                  <!-- Start Tampilan Harga Produk -->
                  <div class="hargaotr">
                    <h6 style="font-family: Poppins" class="car-single-price">
                      Harga OTR
                    </h6>
                    <span
                      class="mt-5"
                      style="
                        color: #1f1c1c;
                        font-size: 34px;
                        font-weight: bold;
                        padding: 0px 10px 0px 0px;
                        font-family: Poppins;
                      "
                      v-if="selectedType"
                    >
                      Rp {{ formatPrice(harga) }}
                    </span>
                  </div>
                  <!-- End Tampilan Harga Produk -->

                  <!-- Start Tampilan Warna Mobil -->
                  <div class="form-group mt-5">
                    <h6 class="car-single-price">
                      Warna
                      <span
                        style="
                          font-size: 14px;
                          font-weight: 500;
                          color: gray;
                          font-family: Poppins;
                        "
                        >{{ getSelectedColorName(selectedColor) }}</span
                      >
                    </h6>

                    <v-card flat>
                      <v-radio-group v-model="selectedColor" inline>
                        <v-row>
                          <v-col
                            v-for="(image, index) in carWarna"
                            :key="index"
                            cols="2"
                          >
                            <div class="chip-container">
                              <div
                                :style="{
                                  backgroundColor: image.hexColorCode,
                                  color: 'white',
                                  border: '2px solid #4F4E4E',
                                  outline:
                                    selectedChipIndex === index
                                      ? '3px solid #207ACE'
                                      : 'none',
                                  outlineOffset: '-2px',
                                  width: '40px',
                                  height: '40px',
                                  borderRadius: '10%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontSize: '12px',
                                }"
                                @click="
                                  changeImage(image.imagePath, image.id, index)
                                "
                              >
                                <v-icon
                                  v-if="
                                    index === selectedChipIndex &&
                                    (selectedColor !== null ||
                                      selectedSeatIndex !== null)
                                  "
                                  class="check-icon"
                                  style="
                                    float: right;
                                    color: #207ace;
                                    bottom: -30px;
                                    right: -25px;
                                    font-size: 18px;
                                  "
                                >
                                  mdi mdi-checkbox-marked-circle-outline
                                </v-icon>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </div>

                  <!-- Fungsi untuk Pilihan Velg Mobil Lengkap -->
                  <!-- <div v-if="carVelg.length > 0" class="form-group mt-5">
                    <h6 class="car-single-price">Velg</h6>
                    <v-card flat>
                      <v-radio-group v-model="selectedVelg" inline>
                        <v-row>
                          <v-col
                            v-for="(velg, index) in filteredCarVelg"
                            :key="index"
                            cols="2"
                          >
                            <div
                              @click="
                                changeVelgImage(velg.imagePath, velg.id, index)
                              "
                              :class="{
                                'selected-option': index === selectedVelgIndex,
                              }"
                            >
                              <img
                                :src="velg.colorName"
                                style="
                                  width: 120px;
                                  height: auto;
                                  border-radius: 50%;
                                "
                                alt="Velg Image"
                              />
                              <v-icon
                                v-if="
                                  index === selectedVelgIndex && bothSelected
                                "
                                class="check-icon"
                                style="
                                  float: right;
                                  color: #207ACE;
                                  font-size: 18px;
                                "
                              >
                                mdi mdi-checkbox-marked-circle-outline
                              </v-icon>
                            </div>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </div>
                  <div v-if="selectedVelgIndex !== null">
                    <span>Tambahan Harga Velg:</span>
                    <h5 class="car-single-price">
                      Rp
                      {{
                        formatPrice(filteredCarVelg[selectedVelgIndex].price)
                      }}
                    </h5>
                  </div> -->
                  <!-- Fungsi untuk Seat Mobil -->
                  <!-- <div v-if="carSeat.length > 0" class="form-group mt-5">
                    <h6 class="car-single-price">Interior</h6>
                    <v-card flat>
                      <v-radio-group v-model="selectedSeat" inline>
                        <v-row>
                          <v-col
                            v-for="(seat, index) in carSeat"
                            :key="index"
                            cols="2"
                          >
                            <div class="chip-container">
                              <div
                                :style="{
                                  backgroundColor: seat.hexColorCode,
                                  color: 'white',
                                  border: '2px solid #4F4E4E',
                                  outline:
                                    selectedSeatIndex === index
                                      ? '3px solid #207ACE'
                                      : 'none',
                                  outlineOffset: '-2px',
                                  width: '40px',
                                  height: '40px',
                                  borderRadius: '10%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontSize: '12px',
                                }"
                                @click="
                                  changeSeatImage(
                                    seat.imagePath,
                                    seat.id,
                                    index
                                  )
                                "
                              >
                                <v-icon
                                  v-if="
                                    index === selectedSeatIndex &&
                                    (selectedColor !== null ||
                                      selectedSeatIndex !== null)
                                  "
                                  style="
                                    float: right;
                                    color: #207ACE;
                                    bottom: -30px;
                                    right: -25px;
                                    font-size: 18px;
                                  "
                                >
                                  mdi mdi-checkbox-marked-circle-outline
                                </v-icon>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-radio-group>
                    </v-card>
                  </div>
                  <div v-if="selectedSeatIndex !== null">
                    <span>Tambahan Harga Interior:</span>
                    <h5 class="car-single-price">
                      Rp
                      {{ formatPrice(carSeat[selectedSeatIndex].price) }}
                    </h5>
                  </div>
                  <div v-if="totalPrice !== 0">
                    <hr />
                    <center>
                      <h5 class="car-single-price">Total Harga</h5>
                      <span
                        class="mt-5"
                        style="
                          color: #1f1c1c;
                          font-size: 34px;
                          font-weight: bold;
                          padding: 0px 10px 0px 0px;
                        "
                      >
                        Rp {{ formatPrice(totalPrice) }}
                      </span>
                    </center>
                  </div> -->
                  <hr />
                  <!-- End Tampilan Warna Mobil -->

                  <!-- Tampilan Tabs Angsuran dan Sewa -->
                  <v-tabs
                    v-model="activeTab"
                    fixed-tabs
                    bg-color=""
                    color="deep-purple-accent-4"
                    align-tabs="center"
                    style="color: #0c5eaa"
                  >
                    <v-tab style="color: #0c5eaa"> <b>Angsuran</b> </v-tab>
                    <v-tab style="color: #0c5eaa"> <b>Tunai</b> </v-tab>

                    <!-- Start Tampilan Angsuran -->
                    <v-tab-item>
                      <v-card>
                        <v-card-text>
                          <div class="form-group mt-5">
                            <div class="form-group mt-5">
                              <!-- Start Tampilan Tipe, DP, MITRA, WILAYAH -->
                              <div class="tipe">
                                <v-row class="mt-5">
                                  <v-col cols="12">
                                    <h6 class="car-single-price">Tipe Mobil</h6>
                                    <div class="custom-select-wrapper">
                                      <select
                                        class="nice-select"
                                        style="width: 100%"
                                        v-model="selectedType"
                                        @change="updateHarga"
                                      >
                                        <!-- <option value="">Pilihan Tipe</option> -->
                                        <option
                                          v-for="grade in carType"
                                          :key="grade.id"
                                          :value="grade.type"
                                        >
                                          {{ grade.type }}
                                        </option>
                                      </select>

                                      <div class="custom-select-arrow"></div>
                                    </div>
                                  </v-col>
                                </v-row>

                                <v-row class="mt-5">
                                  <v-col cols="8">
                                    <h6 class="car-single-price">DP (Rp)</h6>

                                    <div class="input-group mb-3">
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text"
                                          style="
                                            height: 50px;
                                            border: 1px solid black;
                                          "
                                          id="basic-addon1"
                                          >Rp</span
                                        >
                                      </div>
                                      <input
                                        type="text"
                                        v-model="dp"
                                        @input="handleRpInput"
                                        class="form-control nice-input"
                                        placeholder="Masukkan dalam Rp"
                                        aria-describedby="basic-addon1"
                                      />
                                    </div>
                                  </v-col>
                                  <v-col cols="4">
                                    <h6 class="car-single-price">%</h6>
                                    <div class="input-group">
                                      <input
                                        v-model="uangMukaPersentase"
                                        @input="handlePersentaseInput"
                                        type="text"
                                        class="form-control nice-input"
                                        aria-label="Amount (to the nearest dollar)"
                                      />
                                      <div class="input-group-append">
                                        <span
                                          style="
                                            height: 50px;
                                            border: 1px solid black;
                                          "
                                          class="input-group-text"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </v-col>
                                </v-row>

                                <!-- <v-row class="mt-5">
                                  <v-col cols="12">
                                    <h6 class="car-single-price">Mitra</h6>
                                    <div class="custom-select-wrapper">
                                      <select
                                        class="nice-select"
                                        v-model="selectedMitra"
                                        @change="fetchData"
                                      >
                                        <option
                                          v-for="bank in banks"
                                          :key="bank.id_bank"
                                          :value="bank.id_bank"
                                        >
                                          {{ bank.nama_bank }}
                                        </option>
                                      </select>
                                      <div class="custom-select-arrow"></div>
                                    </div>
                                  </v-col>
                                </v-row> -->

                                <v-row class="mt-5">
                                  <v-col cols="12">
                                    <h6 class="car-single-price">Wilayahs</h6>
                                    <div class="custom-select-wrapper">
                                      <select
                                        class="nice-select"
                                        v-model="selectedWilayah"
                                        @change="fetchData"
                                      >
                                        <option
                                          v-for="area in wilayah"
                                          :key="area.id_wilayah"
                                          :value="area.id_wilayah"
                                        >
                                          {{ area.nama }}
                                        </option>
                                      </select>
                                      <div class="custom-select-arrow"></div>
                                    </div>
                                  </v-col>
                                </v-row>
                              </div>
                              <!-- End Tampilan Tipe, DP, MITRA, WILAYAH -->

                              <!-- Start Tampilan Button Hitung Angsuran -->
                              <v-row class="mt-5">
                                <v-col cols="12">
                                  <div>
                                    <button
                                      @click="fetchSimulasi"
                                      type="submit"
                                      class="button-angsuran"
                                    >
                                      <span v-if="isLoading">
                                        <i class="fas fa-spinner fa-spin"></i>
                                        Loading...
                                      </span>
                                      <span v-else>Hitung Angsuran</span>
                                    </button>
                                    <button
                                      v-if="carDetail[0].id === '77'"
                                      type="submit"
                                      class="button-ta"
                                      @click="bookTestDrive"
                                    >
                                      Ajukan Test Drive
                                    </button>
                                  </div>
                                </v-col>
                              </v-row>

                              <!-- End Tampilan Button Hitung Angsuran -->
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- End Tampilan Angsuran -->

                    <!-- Start Tampilan Sewa -->
                    <v-tab-item>
                      <v-card>
                        <v-card-text>
                          <div class="form-group mt-5">
                            <div class="form-group mt-5">
                              <div class="tipe">
                                <v-row class="mt-5">
                                  <v-col cols="12">
                                    <h6 class="car-single-price">Tipe Mobil</h6>
                                    <div class="custom-select-wrapper">
                                      <select
                                        class="nice-select"
                                        style="width: 100%"
                                        v-model="selectedType"
                                        @change="updateHarga"
                                      >
                                        <!-- <option value="">Pilihan Tipe</option> -->
                                        <option
                                          v-for="grade in carType"
                                          :key="grade.id"
                                          :value="grade.type"
                                        >
                                          {{ grade.type }}
                                        </option>
                                      </select>

                                      <div class="custom-select-arrow"></div>
                                    </div>
                                  </v-col>
                                </v-row>

                                <!-- Elemen HTML untuk Menampilkan Hasil Simulasi -->
                              </div>
                              <v-row class="mt-5">
                                <v-col cols="12">
                                  <div>
                                    <button
                                      type="submit"
                                      class="button-angsuran"
                                      @click="submitTunai"
                                    >
                                      Ajukan Tunai
                                    </button>
                                    <button
                                      v-if="carDetail[0].id === '77'"
                                      type="submit"
                                      class="button-ta"
                                      @click="bookTestDrive"
                                    >
                                      Ajukan Test Drive
                                    </button>
                                  </div>
                                </v-col>
                              </v-row>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- End Tampilan Sewa -->
                  </v-tabs>
                </div>
              </div>
            </div>

            <!-- Start tampilan rekomondasi produk -->
            <div class="d-none d-lg-block container">
              <div class="d-flex justify-content-between">
                <h3 style="color: #207ace" class="car-single-title">
                  Rekomendasi
                </h3>
              </div>
              <div class="container">
                <div class="row">
                  <div v-for="c in carRekomendasi" :key="c.id" class="col-3">
                    <div
                      class="wow fadeInUp"
                      data-wow-delay=".25s"
                      style="
                        box-shadow: 20px;
                        background: white;
                        border-radius: 10px;
                        padding: 10px;
                        height: auto;
                        margin-bottom: 25px;
                        box-shadow: var(--box-shadow);
                        transition: var(--transition);
                      "
                    >
                      <div class="car-img">
                        <center>
                          <img
                            :src="c.imagePath"
                            style="max-width: 250px; height: 170px"
                            alt=""
                          />
                        </center>
                      </div>
                      <div class="car-content">
                        <div class="car-top">
                          <h4>
                            <a
                              style="
                                color: black;
                                display: inline-block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                max-width: 100%;
                                font-weight: 500;
                                font-family: Poppins;
                              "
                              href="#"
                              >{{ c.name }}</a
                            >
                          </h4>
                          <span
                            style="color: #207ace; font-family: Poppins"
                            class="car-price"
                          >
                            Rp {{ formatPrice(c.range_harga) }}
                          </span>
                        </div>
                        <v-row no-gutters>
                          <v-col>
                            <v-chip
                              class="mt-2"
                              closable
                              style="padding: 5px"
                              color="#207ACE"
                              text-color="#207ACE"
                              label
                            >
                              <span
                                style="
                                  color: white;
                                  font-weight: 500;
                                  font-size: 14px;
                                  font-family: Poppins;
                                "
                                >{{ c.transmisi }} | {{ c.bbm }}</span
                              ></v-chip
                            >
                          </v-col>
                          <v-col
                            style="
                              display: flex;
                              align-items: center;
                              justify-content: flex-end;
                            "
                          >
                            <div>
                              <li></li>
                            </div>
                          </v-col>
                        </v-row>
                        <button
                          @click="handledetaillates(c.slug)"
                          class="btn-detail"
                          style="font-family: Poppins"
                        >
                          Detail Mobil
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End tampilan rekomondasi produk -->
          </div>
        </div>
      </div>
    </div>

    <foote />
  </div>
</template>

<script>
import navigation from "../components/backup.vue";
import foote from "../components/Footer";
import axios from "axios";
import Swal from "sweetalert2";
import { Head } from "vue-head";
import VuePinchZoom from "vue-pinch-zoom";
export default {
  name: "DetailLates",
  components: {
    navigation,
    foote,
    Head,
    VuePinchZoom,
  },
  data() {
    return {
      pinchZoomOptions: {
        zoomFactor: 0.1,
      },
      filteredCarImages: [],
      currentImageIndex: 0,
      relatedCars: [],
      uniqueColors: [],
      savedSlug: localStorage.getItem("slug"),
      whatsappNumber: "6281990181108", //number untuk redirect ke whatsapp
      isLoading: false, //loading pada fungsi
      showShareOptions: false,
      // Warna dan image terbaru
      totalPrice: 0,
      selectedChipIndex: 0,
      carWarna: [],
      currentImagePath: "",
      bothSelected: false,
      selectedSeat: null,
      selectedVelgOption: null,
      selectedVelg: null,
      selectedSeatIndex: 0,
      selectedColor: null,
      selectedSeatIndex: null,
      selectedVelgIndex: null,
      carSeat: [],
      carVelgOption: [],
      carVelg: [],
      carRekomendasi: [],

      filteredCarVelg: [],
      showFullText: false,
      maxTextLength: 370,
      slug: "",
      carDetail: [],
      carType: [],
      relatedCars: [], // Data mobil terkait akan disimpan di sini
      limit: 5, // Jumlah batasan data yang ingin ditampilkan
      dp: "",
      uangMukaPersentase: "20",
      selectedMitra: null,
      selectedWilayah: null,
      selectedMitraText: "",
      selectedWilayahText: "",
      banks: [], // Untuk menyimpan data bank dari API
      wilayah: [], // Untuk menyimpan data wilayah dari API
      selectedType: "", // Menyimpan tipe yang dipilih
      harga: "", // Menyimpan harga
      typeCode: "",
      type: "",
      spesifikasi: "",
      transmisi: "",
      warna: "",
      bbm: "",
      typeseatCode: "",
      year: "",
      keterangan: "",
      transmisActiveisi: "",
      listrikNonListrik: "",
      seat: "",
      id: "",
      id_jauto_produk: "",
    };
  },
  computed: {
    isTextTooLong() {
      return this.carDetail[0].deskripsi.length > this.maxTextLength;
    },
    filteredCarVelg() {
      if (this.selectedColor) {
        return this.carVelg.filter(
          (velg) => velg.hexColorCode === this.selectedColor
        );
      } else {
        return this.carVelg;
      }
    },
    filteredRelatedCars() {
      // Adjust the condition based on your requirements
      return this.relatedCars.slice(0, 4);
    },
    productLink() {
      return `http://localhost:8080/detaillates/${this.savedSlug}`;
    },
    // Menghasilkan link untuk dibagikan melalui WhatsApp
    whatsappLink() {
      return `https://api.whatsapp.com/send?text=${encodeURIComponent(
        this.productLink
      )}`;
    },
    // Menghasilkan link untuk dibagikan melalui Facebook
    facebookLink() {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        this.productLink
      )}`;
    },
    // Menghasilkan link untuk dibagikan melalui Twitter
    twitterLink() {
      return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        this.productLink
      )}&text=Check%20out%20this%20awesome%20product!`;
    },
    cleanedDescription() {
      return this.extractCVT(this.carDetail[0].deskripsi);
    },
    pageTitle() {
      return this.carDetail[0].name + " | " + this.selectedType;
    },
    pageDescription() {
      return this.carDetail[0].deskripsi;
    },
  },
  mounted() {
    this.updateHarga();
    // this.changeSeatImage(this.carSeat[0].imagePath, this.carSeat[0].id, 0);
    // Mengambil slug dari localStorage
    this.uniqueColors = this.getUniqueColors(this.carWarna);
    const savedSlug = localStorage.getItem("slug");
    const currentSlug = this.$route.params.slug; //Ini Ambil Link tapi yg belakangnya aja
    const lengkap = this.$route?.fullPath; //Ini Ambil Link tapi yg belakangnya aja depannya tinggal tambahin https://auto.jaja.id/
    console.log(`lengkap`, lengkap);
    localStorage.setItem("slug", currentSlug);
    console.log("saat Refresh:", currentSlug);

    if (!savedSlug) {
      console.log(`gamasuk slugnya`);
      const slug = localStorage.setItem("slug", currentSlug);
      console.log(`dari gamasuk`, currentSlug);
      this.getCarDetail(currentSlug);
      // this.getRelatedCars(currentSlug);
      this.fetchData();
      this.fetchBunga();
      localStorage.removeItem("slug");
    }

    if (savedSlug) {
      // Menggunakan slug yang disimpan untuk mengambil detail mobil
      this.getCarDetail(savedSlug);
      // this.getRelatedCars(savedSlug);
      this.fetchData();
      this.fetchBunga();
      localStorage.removeItem("slug");
    }
  },
  created() {
    // Cek apakah ada data yang tersimpan di localStorage untuk selectedMitraText dan selectedWilayahText
    const savedMitraText = localStorage.getItem("selectedMitraText");
    const savedWilayahText = localStorage.getItem("selectedWilayahText");

    if (savedMitraText && savedWilayahText) {
      this.selectedMitraText = savedMitraText;
      this.selectedWilayahText = savedWilayahText;
    }

    // Panggil fetchData untuk mengisi data banks dan wilayah
    this.selectFirstChip();
    this.fetchData();
    this.getCarDetail(); // Panggil metode getCarDetail saat komponen dibuat

    if (this.carType.length > 0) {
      // Ambil data pertama dari carType
      const firstType = this.carType[0];
      this.selectedType = firstType.typeauto; // Atur pemilihan awal pada select

      // Hitung nilai DP berdasarkan persentase default dan harga
      const defaultPersentase = parseFloat(
        this.uangMukaPersentase.replace(/\D/g, "")
      );
      const harga = parseFloat(this.harga.replace(/\D/g, "")); // Pastikan harga sudah dalam format angka
      const dpNominal = ((defaultPersentase / 100) * harga).toFixed(0);

      // Format nilai DP dalam format rupiah dengan tanda ribuan yang sesuai
      this.dp = `${dpNominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    }
  },

  methods: {
    bookTestDrive() {
      // Menyimpan data ke localStorage
      localStorage.setItem("carId", this.carDetail[0].id);
      localStorage.setItem("carName", this.carDetail[0].name);
      // Mengarahkan pengguna ke halaman test drive
      this.$router.push({ path: "/testdrive" });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    getSelectedColorName(selectedColor) {
      // Find the color object based on selectedColor
      const colorObject = this.carWarna.find(
        (color) => color.hexColorCode === selectedColor
      );
      // Return the colorName if colorObject exists, otherwise return selectedColor
      return colorObject ? colorObject.colorName : selectedColor;
    },
    getUniqueColors(colors) {
      const uniqueColors = [];
      const colorSet = new Set();

      for (const color of colors) {
        if (!colorSet.has(color.hexColorCode)) {
          colorSet.add(color.hexColorCode);
          uniqueColors.push(color);
        }
      }

      return uniqueColors;
    },
    changeColor(hexColorCode, index) {
      this.selectedChipIndex = index;
      // Set selected color
      this.selectedColor = hexColorCode;

      // Set selected color ID
      this.selectedColorId = this.uniqueColors[index].id;

      // Filter images based on the selected hex color code
      this.filteredCarImages = this.carWarna.filter(
        (image) => image.hexColorCode === this.selectedColor
      );

      // Set the current image index to the first image
      this.currentImageIndex = 0;

      // Update other properties as needed
      this.updateBothSelected();
      this.updateTotalPrice();
    },
    // sanitizeHTML(html) {
    //   var doc = new DOMParser().parseFromString(html, "text/html");
    //   var sanitizedHTML = doc.body.textContent || "";
    //   return sanitizedHTML;
    // },
    updateTotalPrice() {
      let totalPrice = parseFloat(this.harga); // Base price

      // Add the price of the selected velg
      if (
        this.selectedVelgIndex !== null &&
        this.filteredCarVelg.length > this.selectedVelgIndex
      ) {
        totalPrice += parseFloat(
          this.filteredCarVelg[this.selectedVelgIndex].price
        );
      }

      // Add the price of the selected interior
      if (
        this.selectedSeatIndex !== null &&
        this.carSeat.length > this.selectedSeatIndex
      ) {
        totalPrice += parseFloat(this.carSeat[this.selectedSeatIndex].price);
      }

      // Update the total price in the template
      this.totalPrice = totalPrice;
    },
    truncateText(html) {
      let cleanedHTML = this.sanitizeHTML(html);

      if (!this.showFullText && this.isTextTooLong) {
        cleanedHTML = cleanedHTML.slice(0, this.maxTextLength) + "...";
      }

      return cleanedHTML.trim();
    },
    sanitizeHTML(html) {
      var cleanedHTML = html.replace(/&nbsp;/g, " ");

      // cleanedHTML = cleanedHTML.replace(/n/g, "");

      cleanedHTML = cleanedHTML.replace(/\s+/g, " ");

      return cleanedHTML.trim();
    },
    shareWhatsApp() {
      const link = this.generateShareLink();
      const productDescription = encodeURIComponent(
        "Temukan keseruan baru dengan Mobil impian anda di Auto.jaja.id!"
      );
      const whatsappLink = `https://wa.me/?text=${productDescription}%0A${encodeURIComponent(
        link
      )}`;
      window.open(whatsappLink, "_blank");
    },
    shareFacebook() {
      const link = this.generateShareLink();
      const productDescription = encodeURIComponent(
        "Temukan keseruan baru dengan Mobil impian anda di Auto.jaja.id!"
      );
      const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        link
      )}&quote=${productDescription}`;
      window.open(facebookLink, "_blank");
    },
    copyLink() {
      const link = this.generateShareLink();
      const el = document.createElement("textarea");
      el.value = link;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      alert("Link telah disalin!");
    },
    generateShareLink() {
      return `https://auto.jaja.id${this.$route?.fullPath}`;
    },
    toggleShareOptions() {
      this.showShareOptions = !this.showShareOptions;
    },
    extractCVT(input) {
      // Extract only the text "CVT"
      const match = input.match(/CVT/i);
      return match ? match[0] : "";
    },
    klikButton() {
      const carName = this.carDetail[0].name;
      const carBrand = this.carDetail[0].brand;
      const formattedHarga = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(this.harga);

      const formattedVelgPrice = this.formatPrice(
        this.filteredCarVelg[this.selectedVelgIndex]?.price || 0
      );

      const formattedInteriorPrice = this.formatPrice(
        this.carSeat[this.selectedSeatIndex]?.price || 0
      );

      const message = `Halooo Admin Jaja Auto, 

Pilihan Produk : ${carName}
Pilihan Merk : ${carBrand}
Pilihan Tipe: ${this.selectedType}
Harga Produk : ${formattedHarga}

Tambahan Harga Velg: Rp ${formattedVelgPrice}
Tambahan Harga Interior: Rp ${formattedInteriorPrice}

Saya berharap dapat mendiskusikan penawaran sewa ini lebih lanjut. Mohon petunjuk selanjutnya.

Terima kasih,`;

      const encodedMessage = encodeURIComponent(message);
      window.location.href = `https://wa.me/${this.whatsappNumber}?text=${encodedMessage}`;
    },

    calculateTotalPrice() {
      // Calculate total price including car price, velg price, and interior price
      let totalPrice = parseFloat(this.harga);

      if (this.selectedVelgIndex !== null) {
        totalPrice += parseFloat(
          this.filteredCarVelg[this.selectedVelgIndex].price
        );
      }

      if (this.selectedSeatIndex !== null) {
        totalPrice += parseFloat(this.carSeat[this.selectedSeatIndex].price);
      }

      return totalPrice;
    },

    formatPrice(price) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      }).format(price);
    },

    // Fungsi Titik Pada Harga
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("id-ID");
      return formatter.format(price);
    },

    selectFirstChip() {
      this.selectedChipIndex = 0;
      const firstImage = this.carWarna[0].images[0]; // Sesuaikan dengan struktur data Anda
      this.changeImage(firstImage.imagePath, firstImage.id, 0);
    },
    changeImage(imagePath, productImageId, index) {
      this.selectedChipIndex = index;
      this.loadImage(imagePath);
      this.selectedColor = this.carWarna[index].hexColorCode;
      this.filteredCarVelg = this.carVelg.filter(
        (velg) => velg.hexColorCode === this.selectedColor
      );
      // Filter images based on the selected hex color code
      this.filteredCarImages = this.carWarna.filter(
        (image) => image.hexColorCode === this.selectedColor
      );

      // Set the current image index to the first image
      this.currentImageIndex = 0;

      // Update other properties as needed
      this.updateBothSelected();
      this.updateTotalPrice();
    },
    nextImage() {
      // Increment the current image index
      this.currentImageIndex =
        (this.currentImageIndex + 1) % this.filteredCarImages.length;
    },
    prevImage() {
      // Decrement the current image index
      this.currentImageIndex =
        (this.currentImageIndex - 1 + this.filteredCarImages.length) %
        this.filteredCarImages.length;
    },

    changeSeatImage(imagePath, productImageId, index) {
      console.log("Selected interior index:", index);
      this.selectedSeatIndex = index;
      this.loadImage(imagePath);

      // Update both selected and total price
      this.updateBothSelected();
      this.updateTotalPrice();
    },

    // Fungsi untuk opsi velg (carVelgOption)
    changeVelgOptionImage(imagePath, productImageId, index) {
      this.selectedVelgOptionIndex = index;
      this.loadImage(imagePath);
    },
    // Fungsi untuk velg (carVelg)
    changeVelgImage(imagePath, productImageId, index) {
      this.selectedVelgIndex = index;
      console.log("Selected velg index:", index);

      this.loadImage(imagePath);
      this.updateBothSelected();
      this.updateTotalPrice(); // Add this line to update the total price
    },

    updateBothSelected() {
      this.bothSelected =
        this.selectedColor !== null ||
        this.selectedSeatIndex !== null ||
        this.selectedVelgIndex !== null;
    },

    loadImage(imagePath) {
      this.isLoading = true;
      const image = new Image();
      image.src = imagePath;
      image.onload = () => {
        this.currentImagePath = imagePath;
        this.isLoading = false;
      };
      image.onerror = () => {
        console.error("Error loading default image:", imagePath);
        this.currentImagePath = require("../assets/img/kosong/1.png");
        this.isLoading = false;
      };
    },
    zoomImage(event) {
      const image = event.target;
      image.style.transition = "transform 0.3s";
      image.style.transform = "scale(1.2)";
    },
    resetZoom(event) {
      const image = event.target;
      image.style.transition = "transform 0.3s";
      image.style.transform = "scale(1)";
    },

    formatPriceAsRupiah(priceString) {
      const price = parseInt(priceString, 10); // Convert the string to an integer
      if (isNaN(price)) {
        return priceString; // Return the original string if it's not a valid number
      }

      const formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      });

      return formatter.format(price);
    },
    selectedMitraText() {
      const selectedMitraId = this.selectedMitra;
      const selectedMitra = this.data.bank.find(
        (mitra) => mitra.id_bank === selectedMitraId
      );
      const mitraText = selectedMitra ? selectedMitra.nama_bank : "";

      // Simpan nilai ke localStorage
      localStorage.setItem("selectedMitraText", mitraText);

      // Tampilkan nilai yang disimpan di console
      console.log("Nilai mitra yang disimpan:", mitraText);

      return mitraText; // Mengembalikan nama bank yang dipilih
    },
    selectedWilayahText() {
      const selectedWilayahId = this.selectedWilayah;
      const selectedWilayah = this.data.wilayah.find(
        (wilayah) => wilayah.id_wilayah === selectedWilayahId
      );
      const wilayahText = selectedWilayah ? selectedWilayah.nama_wilayah : "";

      // Simpan nilai ke localStorage
      localStorage.setItem("selectedWilayahText", wilayahText);

      return wilayahText; // Mengembalikan nama wilayah yang dipilih
    },
    async hitungAngsuran() {
      this.isLoading = true; // Mengaktifkan animasi loading

      // Validasi semua field form sebelum mengirimkan permintaan
      if (
        this.selectedTipe &&
        this.dp &&
        this.uangMukaPersentase &&
        this.selectedBank &&
        this.selectedWilayah
      ) {
        // Pemeriksaan input persentase
        const persentaseValue = parseFloat(
          this.uangMukaPersentase.replace(/\D/g, "")
        );

        if (persentaseValue < 20 || persentaseValue > 100) {
          Swal.fire({
            icon: "error",
            title: "Persentase Tidak Valid",
            text: "Persentase harus di antara 20% hingga 100%.",
          });
          return; // Menghentikan eksekusi jika persentase tidak valid
        }

        const dpWithoutRp = this.dp.replace(/[^\d]/g, "");

        const dpValue = parseFloat(dpWithoutRp.replace(/\D/g, ""));
        const price = this.datadetail[0].price;

        try {
          // Simulasikan penundaan selama 2 detik
          await new Promise((resolve) => setTimeout(resolve, 1000));

          // Lakukan permintaan API menggunakan axios atau fetch API
          const response = await axios.get(
            `https://api.jaja.id/jauto/produk/count?otr=${price}&dp=${dpWithoutRp}&bank=1&wilayah=${this.selectedWilayah}`,
            {
              headers: {
                Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
              },
            }
          );

          if (response.data.success) {
            this.angsuranData = response.data.data;
            this.showModal = true;
          } else {
            console.error("Gagal mengambil data perhitungan angsuran");
          }
        } catch (error) {
          console.error(
            "Terjadi kesalahan saat mengambil data dari API",
            error
          );
        } finally {
          // Menonaktifkan animasi loading setelah 2 detik
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Harap Lengkapi Semua Field",
          text: "Harap isi semua field sebelum melakukan perhitungan.",
        });
      }
    },
    handlePersentaseInput() {
      // Menghitung nominal (Rp) dari persentase (%) saat pertama kali dimuat
      const price = parseFloat(this.harga.toString().replace(/\D/g, ""));
      const persentase = parseFloat(this.uangMukaPersentase.replace(/\D/g, ""));

      const nominal = ((persentase / 100) * price).toFixed(0); // Menggunakan 0 desimal

      // Format nominal dalam format rupiah dengan tanda ribuan yang sesuai
      this.dp = ` ${nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
    },
    handleRpInput() {
      if (this.harga > 0) {
        const price = parseFloat(this.harga.toString().replace(/\D/g, ""));
        let nominal = parseFloat(this.dp.replace(/\D/g, ""));

        if (nominal > price) {
          nominal = price;
        }

        // Menghapus tanda 'Rp' dari nominal
        this.dp = `${nominal.toLocaleString("id-ID")}`;

        const persentase = ((nominal / price) * 100).toFixed(1);

        this.uangMukaPersentase = !isNaN(persentase) ? persentase + "%" : "0%";

        if (persentase > 100) {
          Swal.fire({
            icon: "error",
            title: "Gagal",
            text: "Persentase melebihi 100%.",
          });
        }
      } else {
        this.dp = "0";
        this.uangMukaPersentase = "0%";
      }
    },
    async getCarDetail(slug) {
      let id = this.$route.params.slug;
      try {
        const response = await fetch(
          // `https://api.jaja.id/jauto/produk/get_produk_detail?slug=${slug}`,
          `https://staging-api.jaja.id/product/get-product-detail?slug=${slug}`,
          {
            method: "GET",
            // headers: {
            //   Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
            // },
          }
        );
        const data = await response.json();
        console.log("ini detaill", data.data);
        if (data) {
          this.carDetail = data.data;
          console.log("ini data detail", this.carDetail);

          this.carWarna = data.data[0].images;
          this.carSeat = data.data[0].seat;
          this.carVelgOption = data.data[0].velg_opt;
          this.carVelg = data.data[0].velg;
          this.carRekomendasi = data.data[0].recommendation;

          if (this.carWarna.length > 0) {
            const firstColor = this.carWarna[0];
            this.selectedColor = firstColor.hexColorCode; // Atur pemilihan awal pada v-chip
            this.currentImagePath = firstColor.imagePath;

            // Set filteredCarVelg langsung tanpa memerlukan aksi pemilihan warna
            this.filteredCarVelg = this.carVelg.filter(
              (velg) => velg.hexColorCode === this.selectedColor
            );
          }

          this.carType = data.data[0].grades;
          console.log("ini tipe cok", this.carType);

          // Simpan data detail mobil dalam localStorage
          localStorage.setItem("carDetail", JSON.stringify(this.carDetail));

          // Cek apakah ada data dalam carType
          if (this.carType.length > 0) {
            // Ambil data pertama dari carType
            const firstCarType = this.carType[0];
            this.selectedType = firstCarType.type; // Atur selectedType ke data pertama
            this.harga = firstCarType.range_harga; // Atur harga ke data pertama
            this.typeCode = firstCarType.typeCode;
            this.type = firstCarType.type;
            this.spesifikasi = firstCarType.spesifikasi;
            this.transmisi = firstCarType.transmisi;
            this.warna = firstCarType.warna;
            this.bbm = firstCarType.bbm;
            this.seat = firstCarType.seat;
            this.typeseatCode = firstCarType.seat;
            this.year = firstCarType.year;
            this.keterangan = firstCarType.keterangan;
            this.transmisActiveisi = firstCarType.isActive;
            this.listrikNonListrik = firstCarType.listrikNonListrik;
            this.id = firstCarType.id;
            this.id_jauto_produk = firstCarType.id_jauto_produk;
          }
          localStorage.removeItem("slug");
        } else {
          console.error("Failed to fetch car details.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    getRelatedCars(slug) {
      // Membuat permintaan API untuk mendapatkan mobil terkait
      axios
        .get(`https://api.jaja.id/jauto/produk/get_related?slug=${slug}`, {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
          },
        })
        .then((response) => {
          this.relatedCars = response.data.data; // Menyimpan data mobil terkait dari respons API
          console.log("ini rekomen", this.relatedCars);
          if (!Array.isArray(this.relatedCars)) {
            this.relatedCars = []; // Inisialisasi sebagai array kosong jika tidak
          } else {
            // Batasi jumlah produk menjadi 4
            this.relatedCars = this.relatedCars.slice(0, 4);
          }
        })
        .catch((error) => {
          console.error("Gagal mengambil mobil terkait:", error);
        });
    },
    handledetaillates(carslug) {
      console.log("slugss", carslug);

      // Ambil slug yang lama dari local storage
      const oldSlug = localStorage.getItem("slug");

      // Simpan c.slug yang baru di local storage
      localStorage.setItem("slug", carslug);

      // Hapus data detail mobil yang terkait dengan slug lama dari local storage
      localStorage.removeItem(`carDetail_${oldSlug}`);

      // Cetak informasi URL saat ini ke console log
      console.log("Current URL:", this.$route.fullPath);

      // Redirect ke halaman "detaillates" dengan slug dalam URL
      localStorage.removeItem("slug");
      console.log(`berhasil di remove`);
      this.$router.push(`/detaillates/${carslug}`);

      // Auto scroll ke atas halaman
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Optional, smooth scroll animation
      });
    },

    updateHarga() {
      console.log("updateHarga called"); // Check if updateHarga is being called
      console.log("uangMukaPersentase:", this.uangMukaPersentase); // Check the value of uangMukaPersentase
      // Simpan tipe yang dipilih ke Local Storage
      localStorage.setItem("selectedType", this.selectedType);

      // Cari harga yang sesuai berdasarkan tipe yang dipilih
      if (this.selectedType) {
        const selectedGrade = this.carDetail[0].grades.find(
          (grade) => grade.type === this.selectedType
        );
        if (selectedGrade) {
          this.harga = selectedGrade.range_harga;
          this.typeCode = selectedGrade.typeCode;
          this.type = selectedGrade.type;
          this.spesifikasi = selectedGrade.spesifikasi;
          this.transmisi = selectedGrade.transmisi;
          this.warna = selectedGrade.warna;
          this.bbm = selectedGrade.bbm;
          this.seat = selectedGrade.seat;
          this.typeseatCode = selectedGrade.seat;
          this.year = selectedGrade.year;
          this.keterangan = selectedGrade.keterangan;
          this.transmisActiveisi = selectedGrade.isActive;
          this.listrikNonListrik = selectedGrade.listrikNonListrik;
          this.id = selectedGrade.id;
          this.id_jauto_produk = selectedGrade.id_jauto_produk;

          const defaultPersentase = parseFloat(
            this.uangMukaPersentase.replace(/\D/g, "")
          ); // Ambil nilai persentase default tanpa tanda persen
          const dpNominal = ((defaultPersentase / 100) * this.harga).toFixed(0);

          // Format nilai DP dalam format rupiah
          this.dp = ` ${dpNominal
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
        } else {
          this.harga = "Tipe tidak valid";
          this.typeCode = "Tipe tidak valid";
          this.type = "Tipe tidak valid";
          this.spesifikasi = "Tipe tidak valid";
          this.transmisi = "Tipe tidak valid";
          this.warna = "Tipe tidak valid";
          this.bbm = "Tipe tidak valid";
          this.typeseatCode = "Tipe tidak valid";
          this.year = "Tipe tidak valid";
          this.keterangan = "Tipe tidak valid";
          this.transmisActiveisi = "Tipe tidak valid";
          this.listrikNonListrik = "Tipe tidak valid";
        }
      } else {
        this.harga = "Pilih Tipe";
      }
    },
    fetchData() {
      // Fetch wilayah data
      axios
        .get("https://staging-api.jaja.id/alamat/get-wilayah-utama")
        .then((response) => {
          this.wilayah = response.data.data.selectBank;
          if (!this.selectedWilayah && this.wilayah.length > 0) {
            this.selectedWilayah = this.wilayah[0].id_wilayah;
          }
          const selectedWilayah = this.wilayah.find(
            (wilayah) => wilayah.id_wilayah === this.selectedWilayah
          );
          this.selectedWilayahText = selectedWilayah
            ? selectedWilayah.nama
            : "";
          localStorage.setItem("selectedWilayahText", this.selectedWilayahText);
        })
        .catch((error) => {
          console.error("Gagal mengambil data wilayah:", error);
        });

      // Fetch bank data
      axios
        .get(
          "https://staging-api.jaja.id/bank/get-bank?page=0&limit=0&keyword="
        )
        .then((response) => {
          this.banks = response.data.data.data;
          if (!this.selectedBank && this.banks.length > 0) {
            this.selectedBank = this.banks[0].id_bank;
          }
          const selectedBank = this.banks.find(
            (bank) => bank.id_bank === this.selectedBank
          );
          this.selectedBankText = selectedBank ? selectedBank.name : "";
          localStorage.setItem("selectedBankText", this.selectedBankText);
        })
        .catch((error) => {
          console.error("Gagal mengambil data bank:", error);
        });
    },

    isFormValid() {
      return (
        this.selectedType &&
        this.dp &&
        this.uangMukaPersentase 
        // this.selectedMitra &&
        // this.selectedWilayah
      );
    },
    async submitTunai() {
      try {
        // Get values for the API call
        const otr = this.harga;
        const id_produk_grades = this.id;

        // Get velg_price and interior_price if they exist, otherwise set default values
        const velg_price =
          this.filteredCarVelg[this.selectedVelgIndex]?.price || 0;
        const id_velg = this.filteredCarVelg[this.selectedVelgIndex]?.id || 0;
        const interior_price = this.carSeat[this.selectedSeatIndex]?.price || 0;
        const id_interior = this.carSeat[this.selectedSeatIndex]?.id || 0;
        const id_body = this.carWarna[this.selectedChipIndex]?.id || 0;
        const metodeBayar = "Cash";

        // Make the API call
        const response = await fetch(
          `https://staging-api.jaja.id/angsuran/get-kredit?otr=${otr}&id_produk_grades=${id_produk_grades}&velg_price=${velg_price}&interior_price=${interior_price}&id_body=${id_body}&id_velg=${id_velg}&id_interior=${id_interior}&metodeBayar=${metodeBayar}`,
         // `https://api.jaja.id/jauto/produk/count?otr=${otr}&id_produk_grades=${id_produk_grades}&velg_price=${velg_price}&interior_price=${interior_price}&id_body=${id_body}&id_velg=${id_velg}&id_interior=${id_interior}&metodeBayar=${metodeBayar}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
            },
          }
        );

        // Check if the API call was successful
        if (response.ok) {
          const result = await response.json();

          // Store the result in localStorage
          localStorage.setItem("tunaiResult", JSON.stringify(result));
          console.log("Stored data:", result);

          // Redirect to the "ProsesTunai" page
          this.$router.push({ name: "ProsesTunai" });

          // Setelah redirect, lakukan autoscroll ke atas
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          // Handle the error if the API call fails
          console.error("API call failed");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },

    async fetchSimulasi() {
      this.isLoading = true;
      if (!this.isFormValid()) {
        // Show an alert if any of the required fields is empty
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: "Semua form harus diisi.",
          confirmButtonText: "OK",
          didOpen: () => {
            const confirmButton = document.querySelector(".swal2-confirm");
            if (confirmButton) {
              confirmButton.style.height = "50px"; // Atur tinggi sesuai keinginan Anda
              confirmButton.style.backgroundColor = "#07528E"; // Atur warna latar belakang sesuai keinginan Anda
              confirmButton.style.color = "white"; // Atur warna teks sesuai keinginan Anda
            }
          },
        });

        this.isLoading = false; // Menghentikan animasi loading
        return;
      }

      // Validasi persentase di sini
      const persentase = parseFloat(
        this.uangMukaPersentase.replace(/[^\d.]/g, "")
      );
      if (persentase < 20) {
        // Menampilkan alert jika persentase kurang dari 20%
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: "Harga Down Payment tidak boleh kurang dari 20%",
          confirmButtonText: "OK",
          didOpen: () => {
            const confirmButton = document.querySelector(".swal2-confirm");
            if (confirmButton) {
              confirmButton.style.height = "50px"; // Atur tinggi sesuai keinginan Anda
              confirmButton.style.backgroundColor = "#07528E"; // Atur warna latar belakang sesuai keinginan Anda
              confirmButton.style.color = "white"; // Atur warna teks sesuai keinginan Anda
            }
          },
        });
        this.isLoading = false; // Menghentikan animasi loading
        return;
      }
      if (persentase > 100) {
        // Menampilkan alert jika persentase lebih dari 100%
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: "Persentase harus kurang dari atau sama dengan 100%.",
          confirmButtonText: "OK",
          didOpen: () => {
            const confirmButton = document.querySelector(".swal2-confirm");
            if (confirmButton) {
              confirmButton.style.height = "50px"; // Atur tinggi sesuai keinginan Anda
              confirmButton.style.backgroundColor = "#07528E"; // Atur warna latar belakang sesuai keinginan Anda
              confirmButton.style.color = "white"; // Atur warna teks sesuai keinginan Anda
            }
          },
        });
        this.isLoading = false; // Menghentikan animasi loading
        return;
      }

      const id_produk_grades = this.id;
      const otr = this.harga;
      const dpWithoutRp = this.dp.replace(/[^\d]/g, "");

      // Get velg and interior prices
      const velg_price =
        this.filteredCarVelg[this.selectedVelgIndex]?.price || 0;
      const interior_price = this.carSeat[this.selectedSeatIndex]?.price || 0;

      // Get the id of the selected color, interior, and velg
      const id_body = this.carWarna[this.selectedChipIndex]?.id || 0;
      const id_interior = this.carSeat[this.selectedSeatIndex]?.id || 0;
      const id_velg = this.filteredCarVelg[this.selectedVelgIndex]?.id || 0;

      // Set the metodeBayar to "Kredit"
      const metodeBayar = "Kredit";

      // Modify the apiUrl to include id_produk_grades, velg_price, interior_price, id_body, id_interior, id_velg, and metodeBayar
      // const apiUrl = `https://api.jaja.id/jauto/produk/count?otr=${otr}&dp=${dpWithoutRp}&bank=1&wilayah=${this.selectedWilayah}&id_produk_grades=${id_produk_grades}&velg_price=${velg_price}&interior_price=${interior_price}&id_body=${id_body}&id_interior=${id_interior}&id_velg=${id_velg}&metodeBayar=${metodeBayar}`;
      const apiUrl = `https://staging-api.jaja.id/angsuran/get-kredit?otr=${otr}&dp=${dpWithoutRp}&bank=1&wilayah=${this.selectedWilayah}&id_produk_grades=${id_produk_grades}&velg_price=${velg_price}&interior_price=${interior_price}&id_body=${id_body}&id_interior=${id_interior}&id_velg=${id_velg}&metodeBayar=${metodeBayar}`;
      try {
        const response = await axios.get(apiUrl, {
          // headers: {
          //   Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
          // },
        });
        const data = response.data;
        if (data.success) {
          // Data simulasi sukses diambil, Anda dapat memprosesnya di sini
          console.log(data.data); // Tampilkan hasil pada konsol

          // Simpan hasil simulasi ke localStorage
          localStorage.setItem("simulasiData", JSON.stringify(data.data));
          localStorage.setItem("simulasiPertama", JSON.stringify(data));

          console.log("Navigasi ke halaman HasilAngsuran");
          this.$router.push({ name: "HasilAngsuran" });
          console.log("Autoscroll ke atas");
          window.scrollTo({ top: 0, behavior: "smooth" });
        } else {
          // Menampilkan pesan jika terjadi kesalahan
          Swal.fire({
            icon: "error",
            title: "Gagal",
            text: "Gagal mengambil data simulasi.",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Menampilkan pesan jika terjadi kesalahan
        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: "Gagal mengambil data simulasi.",
        });
      } finally {
        this.isLoading = false; // Menghentikan animasi loading
      }
    },
  },
  watch: {
    // Watch for changes in route parameters
    $route(to, from) {
      // Extract the slug from the new route
      const newSlug = to.params.slug;

      // Check if the slug has changed
      if (newSlug !== this.slug) {
        // Fetch the new data based on the updated slug
        this.getCarDetail(newSlug);
        // this.getRelatedCars(newSlug);

        // Update the current slug in the component's data
        this.slug = newSlug;
      }
    },
  },
};
</script>

<head>
  <title>{{ pageTitle }}</title>
  <meta name="description" :content="pageDescription">
</head>
<style>
.zoomable-image {
  transition: transform 0.3s; /* Efek transisi saat zoom */
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container {
  /* Tetapkan posisi relatif untuk memastikan elemen tetap di tempatnya */
  position: relative;
  margin-top: -100px; /* Sesuaikan margin atas sesuai kebutuhan */
  z-index: 0; /* Tetapkan z-index yang lebih rendah untuk memastikan gambar di bawah teks */
}

.car-image {
  width: 100%;
}

@media only screen and (max-width: 440px) {
  .contoh-elemen {
    padding-top: 5%;
  }
}
@media only screen and (min-width: 1275px) {
  .contoh-elemen {
    padding-top: 8%;
  }
}

.selected-option {
  /* border: 2px solid #207ACE;   */
  border-radius: 10%;
}

.share-buttons {
  display: flex;
  align-items: center;
}

button {
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  height: 36px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.main-share-btn {
  background-color: #3498db;
  color: #fff;
  border-radius: 10px;
}

.whatsapp-btn {
  background-color: #25d366;
  color: #fff;
  border-radius: 10px;
}

.facebook-btn {
  background-color: #3b5998;
  color: #fff;
  border-radius: 10px;
}

.copy-link-btn {
  background-color: #3498db;
  color: #fff;
  border-radius: 10px;
}

button:hover {
  filter: brightness(1.2);
}

i {
  margin-right: 0px;
}

.share-options {
  display: flex;
}
/* CSS untuk input */
.nice-input {
  border: 1px solid black; /* Warna garis tepi input */
  padding: 10px; /* Ruang dalam input */
  border-radius: 10px; /* Sudut sudut input */
  font-size: 16px; /* Ukuran font teks dalam input */
  width: 100%; /* Lebar input 100% dari wadahnya */
  height: 50px;
  color: black;
}
.button-angsuran {
  height: 50px;
  font-size: 16px;
  color: var(--color-white);
  padding: 12px 20px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;
  background: var(--theme-color);
  box-shadow: var(--box-shadow);
  z-index: 1;
  width: 100%;
}
.button-angsuran:hover {
  background: #fbab44;
  color: black;
}
.button-ta {
  height: 50px;
  font-size: 16px;
  color: #1f1c1c;
  padding: 12px 20px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: 2px solid #1f1c1c;
  background-color: white;
  box-shadow: var(--box-shadow);
  z-index: 1;
  width: 100%;
}
.button-ta:hover {
  background-color: #fbab44;
  color: #1f1c1c;
}
.pz-zoom-button.pz-zoom-control-position-bottom {
  bottom: 16px;
  left: 50%;
  margin-left: 486px;
}
</style>
<style scoped>
@import "../assets/css/all-fontawesome.min.css";
@import "../assets/css/animate.min.css";
@import "../assets/css/bootstrap.min.css";
@import "../assets/css/flaticon.css";
@import "../assets/css/flex-slider.min.css";
@import "../assets/css/jquery-ui.min.css";
@import "../assets/css/magnific-popup.min.css";
@import "../assets/css/nice-select.min.css";
@import "../assets/css/owl.carousel.min.css";
@import "../assets/css/style.css";
</style>
