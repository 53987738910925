<template>
  <div style="background-color: #f8f8f8">
    <!-- Tampilan Navbar -->
    <navigation />
    <div class="contoh-elemen">
      <div style="padding: 5%" class="row">
        <div class="col-lg-8">
          <!-- <div>
        <h1>Proses Tunai</h1>
        <div v-if="result">
          <p>All: {{ result }}</p>
          <p>Product ID: {{ result.id_produk }}</p>
          <p>Product Name: {{ result.name_produk }}</p>
          <p>Product Grades ID: {{ result.id_produk_grades }}</p>
          <p>Product Grades Name: {{ result.name_produk_grades }}</p>

          <p>Grand Total: {{ result.grandtotal }}</p>
        </div>
        <div v-else>
          <p>No data available.</p>
        </div>
      </div> -->
          <div
            style="
              padding: 40px 20px;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 60px;
              border-radius: 20px;
              background: var(--color-neutral-neutral-00, #fff);
            "
          >
            <div>
              <span
                style="
                  color: var(--color-blue-blue-06, #207ACE);
                  font-size: 34px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  letter-spacing: -0.32px;
                  font-family: Poppins;
                "
                >Detail Pengajuan Anda</span
              >
              <br />
              <br />
              <div class="mt-5" v-if="result">
                <v-row>
                  <v-col xs="12" sm="6" md="6">
                    <p class="title-judul-angsuran">Merk</p>
                    <span class="title-angsuran">{{ result.name_jenis }}</span>
                  </v-col>
                  <v-col xs="12" sm="6" md="6">
                    <p class="title-judul-angsuran">Model</p>
                    <span class="title-angsuran">{{ result.name_produk }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col xs="12" sm="6" md="6">
                    <p class="title-judul-angsuran">Tipe</p>
                    <span class="title-angsuran">{{
                      result.name_produk_grades
                    }}</span>
                  </v-col>
                  <v-col xs="12" sm="6" md="6">
                    <p class="title-judul-angsuran">Jenis</p>
                    <span class="title-angsuran">{{ result.name_jenis }}</span>
                  </v-col>
                </v-row>
                <!-- <v-row>
                  <v-col xs="12" sm="6" md="6">
                    <p class="title-judul-angsuran">Harga Interior</p>
                    <span class="title-angsuran">{{
                      formatPriceAsRupiah(result.interior_price)
                    }}</span>
                  </v-col>
                  <v-col xs="12" sm="6" md="6">
                    <p class="title-judul-angsuran">Harga Velg</p>
                    <span class="title-angsuran">{{
                      formatPriceAsRupiah(result.velg_price)
                    }}</span>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col xs="12" sm="6" md="6">
                    <p class="title-judul-angsuran">Harga Unit</p>
                    <span class="title-angsuran"
                      >{{ formatPriceAsRupiah(result.range_harga) }}
                    </span>
                  </v-col>
                  <!-- <v-col xs="12" sm="6" md="6">
                    <p class="title-judul-angsuran">Harga Total</p>
                    <span class="title-angsuran"
                      >{{ formatPriceAsRupiah(result.grandtotal) }}
                    </span>
                  </v-col> -->
                </v-row>
              </div>

              <div v-else>
                <p>No data available.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div
            style="
              border-radius: 20px;
              background: var(--color-neutral-neutral-00, #fff);
              padding: 40px;
            "
          >
            <p
              style="
                color: black;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.32px;
                font-family: Poppins;
              "
            >
              Form Pengajuan Anda
            </p>
            <span
              style="
                color: black;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.27px;
                font-family: Poppins;
              "
              >Mohon diisi dengan sebenar-benarnya</span
            >
            <!-- <span> {{ formatPriceAsRupiah(selectedTenorData) }}</span> -->
            <br />

            <form class="mt-3" @submit.prevent="submitAngsuran">
              <v-row>
                <v-col cols="12" md="12">
                  <div class="form-group">
                    <label style="  font-family: Poppins;" class="label-form">Nama Lengkap</label>
                    <input
                      type="text"
                      style="  font-family: Poppins;"
                      class="mt-3 form-control"
                      v-model="displayedProfile.name"
                      placeholder="Nama Anda"
                      id="inputName"
                      required
                    />
                  </div>
                </v-col>
                <v-col cols="12" md="12">
                  <div class="form-group">
                  <label style="font-family: Poppins" class="label-form"
                    >WhatsApp</label
                  >
                  <div class="input-group mb-3">
                    <div class="mt-3 input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"
                        >+62</span
                      >
                    </div>
                    <input
                      type="tel"
                      style="font-family: Poppins"
                      class="mt-3 form-control"
                      v-model="displayedProfile.phoneNumber"
                      placeholder="No Handphone Anda"
                      aria-describedby="basic-addon1"
                      id="inputNumber"
                      required
                    />
                  </div>
                </div>
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-col cols="12" md="12">
                  <div style="  font-family: Poppins;" class="form-group">
                    <label class="label-form">Alamat Email</label>
                    <input
                      type="email"
                      style="  font-family: Poppins;"
                      class="mt-3 form-control"
                      v-model="displayedProfile.email"
                      placeholder="Email Anda"
                      id="inputEmail"
                      required
                    />
                  </div>
                </v-col>
                <v-col cols="12" md="12">
                  <div style="  font-family: Poppins;" class="form-group">
                    <label class="label-form">Domisili</label>
                    <input
                    style="  font-family: Poppins;"
                      class="mt-3 form-control"
                      v-model="displayedProfile.alamat_lengkap"
                      placeholder="Jakarta"
                      id="inputAlamat"
                      required
                    />
                  </div>
                </v-col>
              </v-row>
              <br />
              <div class="mt-2 form-group">
                <button
                  type="submit"
                  style="height: 50px;  font-family: Poppins; "
                  class="btn-ajukan-angsuran"
                  :disabled="loadingAngsuran"
                >
                  {{
                    loadingAngsuran
                      ? "Sedang memproses data..."
                      : "Ajukan Tunai"
                  }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <foote />
  </div>
</template>
  <script>
import navigation from "../components/backup";
import foote from "../components/Footer";
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "ProsesTunai",
  components: {
    navigation, //Navbar
    foote, // Footer
  },
  data() {
    return {
      result: null,
      loadingAngsuran: false,
      isLoading: false,
      simulasiData: null,
      simulasiPertama: null,
      carDetail: null,
      selectedMitraText: localStorage.getItem("selectedMitraText") || "",
      selectedWilayahText: localStorage.getItem("selectedWilayahText") || "",
      showForm: false, // Data ini mengendalikan visibilitas form
      selectedTenorData: null, // Data untuk menyimpan informasi tenor yang dipilih
      displayedProfile: {
        name: "",
        phoneNumber: "",
        email: "",
        alamat_lengkap: "",
      },
    };
  },
  mounted() {
    this.carDetail = JSON.parse(localStorage.getItem("carDetail"));
    const storedResult = localStorage.getItem("tunaiResult");

    if (storedResult) {
      this.result = JSON.parse(storedResult);
      console.log("Retrieved data:", this.result);
    } else {
      console.error("No data found in localStorage");
      // Handle the case where no data is found in localStorage
    }
  },
  methods: {
    formatPriceAsRupiah(priceString) {
      const price = parseInt(priceString, 10); // Konversi string menjadi bilangan bulat
      if (isNaN(price)) {
        return priceString; // Kembalikan string asli jika bukan angka yang valid
      }

      const formattedPrice = price.toLocaleString("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return formattedPrice;
    },
    formatRupiah(angka) {
      const formatter = new Intl.NumberFormat("id-ID");
      return formatter.format(angka);
    },

    submitAngsuran() {
      this.loadingAngsuran = true;
      const selectedBankId = this.selectedBankId; // Anda harus mengatur nilai ini sesuai dengan pilihan pengguna
      const selectedWilayahId = this.selectedWilayahId; // Anda harus mengatur nilai ini sesuai dengan pilihan pengguna

      // Data untuk dikirim ke API
      const requestData = {
        id_produk_grades: this.result.id_produk_grades,
        // id_body: this.result.id_body,
        // id_velg: this.result.id_velg,
        // id_interior: this.result.id_interior,
        name: this.displayedProfile.name,
        contact_number: '62' + this.displayedProfile.phoneNumber,
        email: this.displayedProfile.email,
        domisili: this.displayedProfile.alamat_lengkap,
        metodeBayar: "Cash",
        tenorTahun: 0,
        // otr: this.result.grandtotal,
        id_bank: 1,
        id_wilayah: 1,
        uang_muka: 50000000,
      };

      // Format harga produk, dp, dan angsuran pertama sebagai rupiah
      // const selectedType = localStorage.getItem("selectedType");
      const selectedType = this.result.name_produk_grades;
      const result = this.result;
      const formattedUnitPrice = this.formatRupiah(result.range_harga);
      const formattedVelgPrice = this.formatRupiah(result.velg_price);
      const formattedInteriorPrice = this.formatRupiah(result.interior_price);
      const formattedGrandTotal = this.formatRupiah(result.grandtotal);

      // Buat pesan WhatsApp dengan data yang dikirim
      const message = `
    Halooo Admin Jaja Auto,
    Perkenalkan, Nama saya ${requestData.name}. Saya tertarik untuk mengetahui lebih lanjut tentang pembelian secara tunai di Jaja Auto.

    Berikut detail mobil yang saya minati:
    Pilihan Tipe: ${selectedType}
    Harga Unit: Rp ${formattedUnitPrice}

    Saya berharap dapat mendiskusikan penawaran ini lebih lanjut. Mohon petunjuk selanjutnya.

    Terima kasih, ${requestData.name}
  `;

      // Lakukan permintaan HTTP POST ke API dengan token header
      axios
        // .post("https://api.jaja.id/jauto/order/submit", requestData, {
        //   headers: {
        //     Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
        //   },
        // })
        .post("https://staging-api.jaja.id/order/submit-order", requestData, {
            // headers: {
            //   Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
            // },
          })
        .then((response) => {
          this.loadingAngsuran = false;
          // Berhasil, tampilkan Sweet Alert
          Swal.fire({
            title: "Sukses!",
            text: "Data berhasil disubmit. Klik OK untuk menghubungi kami via WhatsApp.",
            icon: "success",
            confirmButtonText: "OK",
            didOpen: () => {
              const confirmButton = document.querySelector(".swal2-confirm");
              if (confirmButton) {
                confirmButton.style.height = "50px"; // Atur tinggi sesuai keinginan Anda
                confirmButton.style.backgroundColor = "#07528E"; // Atur warna latar belakang sesuai keinginan Anda
                confirmButton.style.color = "white"; // Atur warna teks sesuai keinginan Anda
              }
            },
          }).then((result) => {
            // Arahkan pengguna ke WhatsApp saat OK diklik
            if (result.isConfirmed) {
              const whatsappNumber = "6285281000160";
              const formattedphonecipto = whatsappNumber.replace(/^0/, "");
              window.open(
                `https://api.whatsapp.com/send?phone=${formattedphonecipto}&text=${encodeURIComponent(
                  message
                )}`
              );
            }
          });
        })
        .catch((error) => {
          this.loadingAngsuran = false;
          // Terjadi kesalahan, tanggapi error di sini jika diperlukan
          console.error("Error submitting data:", error);
        });
    },

    selectTenor(index) {
      this.isLoading = true;
      this.showForm = true;

      // Scroll ke elemen yang sesuai
      this.$nextTick(() => {
        const element = this.$refs.scrollToElement;
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }

        this.selectedTenorData = this.simulasiData[index];
      });

      // Menonaktifkan animasi loading setelah 2 detik
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },

    formatPrice(price) {
      const formatter = new Intl.NumberFormat("id-ID");
      return formatter.format(price);
    },
  },
};
</script>
  <style scoped>
@media only screen and (max-width: 440px) {
  .contoh-elemen {
    padding-top: 0%;
  }
}
@media only screen and (min-width: 1275px) {
  .contoh-elemen {
    padding-top: 8%;
  }
}
.btn-ajukan-angsuran {
  font-size: 16px;
  color: var(--color-white);
  padding: 12px 20px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  border-radius: 13px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;
  background: var(--theme-color);
  box-shadow: var(--box-shadow);
  z-index: 1;
  width: 100%;
}
.btn-ajukan-angsuran:hover {
  background: #FBAB44;
  color: black;
}
.card-tenor {
  border-radius: 10px;
  border: 1px solid var(--color-blue-blue-06, #207ACE);
  background: var(--color-neutral-neutral-00, #fff);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-judul-angsuran {
  font-family: Poppins;
  color: var(--color-neutral-neutral-08, #1f1c1c);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
}
.title-angsuran {
  font-family: Poppins;
  color: var(--color-blue-blue-06, #207ACE);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
}
.ca {
  display: flex;
  padding: 24px 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  background: #e9f7ff;
}
.cb {
  display: flex;
  padding: 24px 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: stretch;
  background: #c3eaff;
}
.text-card {
  color: var(--color-blue-blue-06, #207ACE);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
}
.ca1 {
  display: flex;
  width: 100%;
  padding: 24px 12px;
  justify-content: center;
  align-items: center;

  align-self: stretch;
  border-radius: 10px 10px 0px 0px;
}
.ta1 {
  color: var(--color-blue-blue-05, #207ACE);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
}
.ta2 {
  color: var(--color-blue-blue-06, #207ACE);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
}
.text-ketentuan {
  color: var(--color-neutral-neutral-06, #706d6d);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-jdl-otr {
  color: var(--color-neutral-neutral-06, #706d6d);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}
.label-form {
  color: var(--color-neutral-neutral-08, #1f1c1c);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}
.button {
  /* margin-right: 12px; */
  font-size: 16px;
  color: black;
  padding: 12px 20px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;
  background: var(--color-yellow-yellow-05, #FBAB44);
  box-shadow: var(--box-shadow);
  z-index: 1;
  width: 100%;
}
.button:hover {
  background: #207ACE; /* Change the background color on hover */
  color: white; /* Change the text color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a box shadow on hover */
}
</style>
  