<template>
  <div class="home-3">
    <!-- header area -->
    <navigation />
    <main class="main">
      <!-- Tampilan Mobile -->
      <div class="d-lg-none about-area pb-120 padding">
        <div class="" style="margin-top: -30%">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div
                class="about-right wow fadeInRight"
                style="margin-right: 25px"
                data-wow-delay=".25s"
              >
                <div class="site-heading mb-3">
                  <span
                    style="font-family: Poppins"
                    class="site-title-tagline justify-content-start"
                  >
                    <i class="flaticon-drive"></i> PRIORITAS KAMI
                  </span>
                  <br />
                  <h1 style="font-size: 24px" class="site-title">Be Fast</h1>
                </div>
                <p
                  style="
                    font-size: 14px;
                    color: #000;
                    display: flex;
                    font-family: Poppins;
                  "
                >
                  Menjadi yang tercepat baik dalam Kecepatan untuk Menanggapi,
                  Efisiensi dalam Memberikan Pelayanan, dan Responsivitas dalam
                  bekerja.
                </p>

                <div class="site-heading mb-3">
                  <h1 style="font-size: 24px" class="site-title">
                    Be Accurate
                  </h1>
                </div>
                <p
                  style="
                    font-size: 14px;
                    color: #000;
                    display: flex;
                    font-family: Poppins;
                  "
                >
                  Bertanggung jawab untuk memberikan Informasi yang benar, hasil
                  yang tepat dan layanan yang akurat. Baik dalam pengukuran atau
                  perhitungan, dan kecermatan dalam setiap aspek pekerjaan atau
                  layanan yang diberikan.
                </p>

                <div class="site-heading mb-3">
                  <h1 style="font-size: 24px" class="site-title">
                    Be The Best
                  </h1>
                </div>
                <p
                  style="
                    font-size: 14px;
                    color: #000;
                    display: flex;
                    font-family: Poppins;
                  "
                >
                  Kami selalu Menetapkan standar kualitas yang tinggi untuk
                  produk atau layanan dan berkomitmen untuk memenuhi atau
                  melebihi kebutuhan konsumen, dan memberikan pelayanan yang
                  sebaik baiknya untuk konsumen
                </p>
                <div style="padding-left:-20px" class="row">
                  <div class="col">
                    <button
                      style="font-family: Poppins"
                      class="button-1 mt-4 mr-3"
                    >
                      <router-link to="/kategori">Cari Mobil</router-link>
                    </button>
                  </div>
                  <div class="col">
                    <button
                      to="/kategori"
                      class="button-2 mt-4"
                      @click="redirectWhatsapp"
                      style="font-family: Poppins"
                    >
                      Kontak Kami
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="mt-5"
                style="padding: 10px; margin-left: 25px; margin-right: 25px"
              >
                <span
                  style="
                    color: var(--Color-Neutral-Neutral-08, #1f1c1c);
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                  "
                  >Apa yang kita jual?</span
                >
                <div class="mt-3 row justify-content-between">
                  <div class="col-auto">
                    <img
                      style="width: 50px"
                      src="../assets/img/homepage/usedcar.png"
                    />
                    <p class="mt-3 txt-kat-mobile">Mobil Bekas</p>
                  </div>
                  <div class="col-auto">
                    <img
                      style="width: 50px"
                      src="../assets/img/homepage/tradein.png"
                    />
                    <p class="mt-3 txt-kat-mobile">Tukar Tambah</p>
                  </div>
                  <div class="col-auto">
                    <img
                      style="width: 50px"
                      src="../assets/img/homepage/newcar.png"
                    />
                    <p class="mt-3 txt-kat-mobile">Mobil Baru</p>
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div class="row">
                  <div
                    class="col-6 col-md-4 col-lg-4 no-padding-margin"
                    v-for="type in carJenis.slice(0, limit)"
                    :key="type.jenis_id"
                  >
                    <a
                      href="#"
                      style="
                        padding: 0;

                        margin-bottom: 0;
                        width: 100%;
                      "
                      @click.prevent="selectType(type)"
                    >
                      <div>
                        <img
                          style="width: 100%; height: auto; flex-shrink: 0"
                          :src="type.image"
                          alt=""
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="mt-3"
                style="padding: 10px; margin-left: 25px; margin-right: 25px"
              >
                <span
                  style="
                    color: var(--Color-Neutral-Neutral-08, #1f1c1c);
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                  "
                  >Lokasi Kami?</span
                >
                <div class="mt-5 map-container">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.566822204965!2d106.86903831413868!3d-6.320487613606533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ed0c80bdb61b%3A0x5b721bf299d9f3b3!2sRT.7%2FRW.9%2C%20Ciracas%2C%20East%20Jakarta%20City%2C%20Jakarta!5e0!3m2!1sen!2sid!4v1677566215721!5m2!1sen!2sid"
                    width="100%"
                    height="360px"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    class=""
                  ></iframe>
                           
                </div>
                <div>
                  <span style="font-size:14px; font-weight:400;" class="txt-kat-mobile"
                    >Jl. H. Baping No.100, RT.6/RW.9, Ciracas, Kec. Ciracas,
                    Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta
                    13740</span
                  >
                </div>
                <br />
                <br />
                <div class="mt-5">
                  <span
                    style="
                      color: var(--Color-Neutral-Neutral-08, #1f1c1c);
                      font-size: 28px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: normal;
                    "
                    >Kunjungi kami</span
                  >
                  <div class="mt-5 row">
                    <div class="col">
                      <div class="card-ig">
                        <div class="d-flex justify-content-between">
                          <div class="jarak-txt">
                            <span class="title-ig">Instagram Kami</span>
                            <br />
                            <p class="mt-2 title-btn">@Jaja.auto</p>
                            <button class="btn-ig" @click="openInstagram">
                              <span class="txt-btn">Kunjungi</span>
                            </button>
                          </div>
                          <div></div>
                          <div>
                            <img
                              src="../assets/img/about/instagram.png"
                              alt="Popup Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="card-ig">
                        <div class="d-flex justify-content-between">
                          <div class="jarak-txt">
                            <span class="title-ig">TikTok Kami</span>
                            <br />
                            <p class="mt-2 title-btn">Jaja_auto</p>
                            <button @click="openTiktok" class="btn-ig">
                              <span class="txt-btn">Kunjungi</span>
                            </button>
                          </div>
                          <div></div>
                          <div>
                            <img
                              src="../assets/img/about/toktok.png"
                              alt="Popup Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="card-ig">
                        <div class="d-flex justify-content-between">
                          <div>
                            <div class="jarak-txt">
                              <span class="title-ig">Whatsapp Kami</span>
                              <br />
                              <p class="mt-2 title-btn" style="font-size: 18px">
                                +62 852 8100 0160
                              </p>
                              <button @click="redirectWhatsapp" class="btn-ig">
                                <span class="txt-btn">Kunjungi</span>
                              </button>
                            </div>
                          </div>
                          <div>
                            <img
                              src="../assets/img/about/whatsapp.png"
                              alt="Popup Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-none d-lg-block col-lg-6">
              <div class="about-left wow fadeInLeft" data-wow-delay=".25s">
                <div class="about-img">
                  <img
                    style="width: 100%; border-radius: 10%"
                    src="../assets/img/about/haha.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Tampilan Website -->
      <div class="d-none d-lg-block">
        <div>
          <div class="containerb">
            <div class="banner-tentang">
              <div
                style="
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  width: 100%;
                "
              >
                <span
                  style="
                    color: #fff;
                    font-family: Poppins;
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                  "
                  >About Us</span
                >
                <div>
                  <span class="txt-about">Be Fast</span>
                  <p class="txt-about1">
                    Menjadi yang tercepat baik dalam Kecepatan untuk Menanggapi,
                    Efisiensi dalam Memberikan Pelayanan, dan Responsivitas
                    dalam bekerja.
                  </p>
                  <br />
                  <span class="txt-about">Be Accurate</span>
                  <p class="txt-about1">
                    Bertanggung jawab untuk memberikan Informasi yang benar,
                    hasil yang tepat dan layanan yang akurat. Baik dalam
                    pengukuran atau perhitungan, dan kecermatan dalam setiap
                    aspek pekerjaan atau layanan yang diberikan.
                  </p>
                  <br />
                  <span class="txt-about">Be The Best</span>
                  <p class="txt-about1">
                    Kami selalu Menetapkan standar kualitas yang tinggi untuk
                    produk atau layanan dan berkomitmen untuk memenuhi atau
                    melebihi kebutuhan konsumen, dan memberikan pelayanan yang
                    sebaik baiknya untuk konsumen
                  </p>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding: 200px">
          <span
            style="
              color: var(--Color-Neutral-Neutral-08, #1f1c1c);
              font-size: 48px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            "
            >Produk dan Layanan</span
          >
          <div class="mt-3 row justify-content-between">
            <div class="col-auto">
              <img src="../assets/img/homepage/newcar.png" />
              <p class="mt-3 txt-kat">Mobil Baru</p>
            </div>
            <div class="col-auto">
              <img src="../assets/img/homepage/usedcar.png" />
              <p class="mt-3 txt-kat">Mobil Bekas</p>
            </div>
            <div class="col-auto">
              <img src="../assets/img/homepage/tradein.png" />
              <p class="mt-3 txt-kat">Tukar Tambah</p>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="mt-3">
              <div class="row">
                <div
                  class="col-6 col-md-4 col-lg-4 pr-2 image-jenis no-padding-margin"
                  v-for="(type, index) in carJenis.slice(0, limit)"
                  :key="type.id"
                >
                  <img
                    :src="type.image"
                    :alt="type.jenis_name"
                    class="image"
                    style="width: 100%; height: auto"
                    @mouseover="showText(index)"
                    @mouseout="hideText(index)"
                    @click.prevent="selectType(type)"
                  />
                  <div
                    @click.prevent="selectType(type)"
                    class="overlay"
                    :class="{ 'show-overlay': hoverIndex === index }"
                  >
                    <p
                      @click.prevent="selectType(type)"
                      style="font-size: 52px; font-weight: 600"
                    >
                      {{ type.jenis_name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="padding: 200px">
          <span
            style="
              color: var(--Color-Neutral-Neutral-08, #1f1c1c);
              font-size: 48px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            "
            >Temukan Kami</span
          >
          <div class="mt-5 map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.566822204965!2d106.86903831413868!3d-6.320487613606533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ed0c80bdb61b%3A0x5b721bf299d9f3b3!2sRT.7%2FRW.9%2C%20Ciracas%2C%20East%20Jakarta%20City%2C%20Jakarta!5e0!3m2!1sen!2sid!4v1677566215721!5m2!1sen!2sid"
              width="100%"
              height="360px"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              class=""
            ></iframe>
                     
          </div>
          <div>
            <span
              style="
                color: #000;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.32px;
              "
              >Jl. H. Baping No.100, RT.6/RW.9, Ciracas, Kec. Ciracas, Kota
              Jakarta Timur, Daerah Khusus Ibukota Jakarta 13740</span
            >
          </div>
          <br />
          <br />
          <div class="mt-5">
            <span
              style="
                color: var(--Color-Neutral-Neutral-08, #1f1c1c);
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              "
              >Kunjungi kami</span
            >
            <div class="mt-5 row">
              <div class="col">
                <div class="card-ig">
                  <div class="d-flex justify-content-between">
                    <div class="jarak-txt">
                      <span class="title-ig">Instagram Kami</span>
                      <br />
                      <p class="mt-2 title-btn">@Jaja.auto</p>
                      <button @click="openInstagram" class="btn-ig">
                        <span class="txt-btn">Kunjungi Instagram</span>
                      </button>
                    </div>
                    <div></div>
                    <div>
                      <img
                        src="../assets/img/about/instagram.png"
                        alt="Popup Image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card-ig">
                  <div class="d-flex justify-content-between">
                    <div class="jarak-txt">
                      <span class="title-ig">TikTok Kami</span>
                      <br />
                      <p class="mt-2 title-btn">Jaja_auto</p>
                      <button @click="openTiktok" class="btn-ig">
                        <span class="txt-btn">Kunjungi Tik Tok</span>
                      </button>
                    </div>
                    <div></div>
                    <div>
                      <img
                        src="../assets/img/about/toktok.png"
                        alt="Popup Image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card-ig">
                  <div class="d-flex justify-content-between">
                    <div class="jarak-txt">
                      <span
                        style="
                          color: #000;
                          font-size: 40px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: normal;
                        "
                        >Punya pertanyaan?</span
                      >
                      <br />
                      <p
                        class="mt-5"
                        style="
                          color: var(--Color-Blue-Blue-05, #207ace);
                          font-size: 40px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: normal;
                        "
                      >
                        Hubungi Kami!
                      </p>
                    </div>
                    <div>
                      <div class="jarak-txt">
                        <span class="title-ig">Whatsapp Kami</span>
                        <br />
                        <p class="mt-2 title-btn">+62 852 8100 0160</p>
                        <button class="btn-ig">
                          <span @click="redirectWhatsapp" class="txt-btn"
                            >Hubungi Admin</span
                          >
                        </button>
                      </div>
                    </div>
                    <div>
                      <img
                        src="../assets/img/about/whatsapp.png"
                        alt="Popup Image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <foote />
  </div>
</template>

<style scoped>
@import "../assets/css/all-fontawesome.min.css";
@import "../assets/css/animate.min.css";
@import "../assets/css/bootstrap.min.css";
@import "../assets/css/flaticon.css";
@import "../assets/css/flex-slider.min.css";
@import "../assets/css/jquery-ui.min.css";
@import "../assets/css/magnific-popup.min.css";
@import "../assets/css/nice-select.min.css";
@import "../assets/css/owl.carousel.min.css";
@import "../assets/css/style.css";

* {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

.carousel {
  background: #fafafa;
  margin-bottom: 40px;
}
.no-padding-margin {
  padding: 0 !important;
  margin: 0 !important;
}
.button-1 {
  font-size: 16px;
  color: var(--color-white);
  padding: 6px 10px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: 2px solid var(--theme-color); /* Ubah warna outline menjadi biru */
  background: #1f1c1c;
  box-shadow: var(--box-shadow);
  z-index: 1;
  width: 100%;
}
.button-2 {
  font-size: 16px;
  color: var(--theme-color);
  padding: 6px 10px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: 2px solid #1f1c1c; /* Ubah warna outline menjadi biru */
  box-shadow: var(--box-shadow);
  z-index: 1;
  width: 100%;
}
.carousel-cell {
  width: 200%;
  height: auto;
  margin-right: 10px;
  /* background-color: #eee; */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  /* content: counter(carousel-cell); */
  line-height: 200px;
  font-size: 80px;
  color: white;
}

.carousel-nav .carousel-cell {
  height: 80px;
  width: 100px;
}

.carousel-nav .carousel-cell:before {
  font-size: 50px;
  line-height: 80px;
}

.carousel-nav .carousel-cell.is-nav-selected {
  background: #ed2;
}
.txt-about {
  padding-right: 300px;
  padding-left: 300px;
  color: var(--Color-Neutral-Neutral-01, #f4f4f4);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  letter-spacing: 0.4px;
}
.txt-about1 {
  padding-right: 300px;
  padding-left: 300px;
  color: var(--Color-Neutral-Neutral-01, #f4f4f4);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
  letter-spacing: 0.4px;
}
.txt-drive {
  color: var(--Color-Neutral-Neutral-00, #fff);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  padding: 20px;
}
.container1 {
  position: relative;
}

.image-containerb {
  position: relative;
  display: inline-block;
}

.centered-buttonn {
  height: 50px;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; /* Make sure the button is above the image */

  border: 2px solid var(--Color-Neutral-Neutral-00, #fff);
}
.txt-kat {
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.txt-kat-mobile {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.jarak-txt {
  margin-left: 100px;
  margin-top: 80px;
}
.card-ig {
  position: relative;
  width: 100%;
  height: 289.545px;
  flex-shrink: 0;
  border-radius: 40px;
  border: 1px solid var(--Color-Neutral-Neutral-08, #1f1c1c);
  background: var(--Neutral-01, #fff);
  overflow: hidden; /* Menambahkan properti overflow */
}

.title-btn {
  color: var(--Color-Blue-Blue-05, #207ace);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
}
.title-ig {
  color: var(--Neutral-05, #1f1e1e);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
}
.btn-ig {
  display: flex;
  height: 40px;
  padding: 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--Color-Yellow-Yellow-05, #fbab44);
}
.txt-btn {
  color: var(--Color-Neutral-Neutral-08, #1f1c1c);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .padding {
    margin-top: 35%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .padding {
    margin-top: 30%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .padding {
    margin-top: 25%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .padding {
    margin-top: 20%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .padding {
    margin-top: 20%;
  }
}
.banner-tentang {
  background-image: url("../assets/img/homepage/bg-about.png");
  width: 100%;
  height: 1080px;
  display: flex;
  align-items: center;
}
.image-jenis {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.overlay {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.show-overlay {
  opacity: 1;
}

.image-jenis:hover .overlay {
  opacity: 1;
}

.image-jenis img {
  display: block;
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.image-jenis:hover img {
  transform: scale(1.1);
}
</style>

<script scope>
import navigation from "../components/backup.vue";
import Flickity from "flickity";
import "flickity/css/flickity.css";
import foote from "../components/Footer";
import axios from "axios";

export default {
  data() {
    return {
      carJenis: [],
      limit: 1000,
      activeSlide: 0,
      intervalId: null,
      kategoriData: [],
      selectedMerkData: [],
      selectedTransmisi: [],
      selectedBbm: [],
      searchKey: "",
      merekOptions: [],
      jenisOptions: [],
      kategoriOptions: [],
      selectedKategori: "",

      carPopular: [],
      carListrik: [],
      carData: [],
      carBrands: [],
      carJenis: [],
      limit: 1000,
      searchKey: "",
      selectedMerek: "",
      selectedTransmisi: "",
      selectedBbm: "",
      selectedSeat: "",
      // selectedListrik: "",
      selectedJenis: [],
      selectedjenis: "",
      selectedSortBy: "",
      selectedSortOrder: "",
      merekOptions: [],
      transmisiOptions: [],
      bbmOptions: [],
      seatOptions: [],
      // listrikOptions: [],
      jenisOptions: [],
      sortByOptions: [],
      sortOrderOptions: [],

      searchResults: [], // Store search results
      carItems: [], // Daftar mobil dari response API
      filteredCarItems: [], // Daftar mobil yang sudah difilter
      uniqueCarTypes: [], // Fungsi Pencarian Berdasarkan Brand
      filteredCarModels: [], // Fungsi Pencarian Berdasarkan Model
      selectedCarType: "", // Opsi yang dipilih untuk Car Brand
      selectedCarModel: "", // Opsi yang dipilih untuk Car Models
      selectedPriceRange: "", // Opsi yang dipilih untuk Price Range
      isLoading: false, // Loading pada filter
      slides: [],
      idcar: "", // ID detail produk
      slug: "",
      carModelSearch: "",
    };
  },
  name: "About",
  created() {
    this.fetchCarJenis();
  },
  methods: {
    showText(index) {
      this.hoverIndex = index;
    },
    hideText(index) {
      this.hoverIndex = null;
    },
    async fetchCarJenis() {
      try {
        const response = await axios.get(
          "https://api.jaja.id/jauto/produk/get_jenis",
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
            },
          }
        );

        // Assuming the response structure matches the provided result
        this.carJenis = response.data.data;
        this.totalCarJenis = this.carJenis.length; // Calculate total car jenis
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching car jenis:", error);
        // Handle error appropriately
      }
    },
    openInstagram() {
      window.open(
        "https://www.instagram.com/jaja.auto/?igshid=MzMyNGUyNmU2YQ%3D%3D&utm_source=qr",
        "_blank"
      );
    },
    openTiktok() {
      window.open(
        "https://www.tiktok.com/@jaja_auto?_t=8hlHN51KqPK&_r=1",
        "_blank"
      );
    },
    redirectWhatsapp() {
      const phoneNumber = "+6285281000160"; // Nomor WhatsApp dengan kode negara +62
      const message =
        "Halo, saya perlu bantuan nih, apakah bisa bantu saya untuk menjelaskan tentang jaja auto?";
      const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
        message
      )}`;
      window.open(url, "_blank");
    },
    async selectType(type) {
      // this.selectedJenis = [{ value: type.jenis_name, label: type.jenis_name }];
      this.selectedJenis = [type.jenis_name];
      this.searchCars();
;
    },
    async searchCarsAndUpdateURL() {
      await localStorage.setItem(
        "selectedMerek",
        JSON.stringify(this.selectedMerek)
      );
      await localStorage.setItem(
        "selectedTransmisi",
        JSON.stringify(this.selectedTransmisi)
      );
      await localStorage.setItem(
        "selectedBbm",
        JSON.stringify(this.selectedBbm)
      );
      await localStorage.setItem(
        "selectedJenis",
        JSON.stringify(this.selectedJenis)
      );

      const params = {
        merek: this.selectedMerek
          ? this.selectedMerek.map((item) => item.value).join(",")
          : "",
        transmisi: this.selectedTransmisi
          ? this.selectedTransmisi.map((item) => item.value).join(",")
          : "",
        bbm: this.selectedBbm
          ? this.selectedBbm.map((item) => item.value).join(",")
          : "",
        seat: this.selectedSeat
          ? this.selectedSeat.map((item) => item.value).join(",")
          : "",
        // listrik: this.selectedListrik
        //   ? this.selectedListrik.map((item) => item.value).join(",")
        //   : "",
        sort_by: this.selectedSortBy ? this.selectedSortBy.value : "",
        sort_order: this.selectedSortOrder ? this.selectedSortOrder.value : "",
        jenis_name: this.selectedJenis
          ? this.selectedJenis.map((item) => item.label).join(",")
          : "",
        kategori_name: this.selectedKategori
          ? this.selectedKategori.map((item) => item.label).join(",")
          : "",

        search: this.searchKey,
        // slug_merek: this.selectedMerek ? this.selectedMerek[0].slug : "",
        // jenis_name: this.selectedJenis ? this.selectedJenis[0].value : "",
      };

      this.$router.replace({
        path: "/search",
        query: params,
      });

      await this.searchCars();
    },
    async searchCars() {
      const params = {
        brand: this.selectedMerek ? this.selectedMerek.join(",") : "",
        transmisi: this.selectedTransmisi
          ? this.selectedTransmisi.join(",")
          : "",
        bbm: this.selectedBbm ? this.selectedBbm.join(",") : "",
        seat: this.selectedSeat ? this.selectedSeat : "",
        sort_by: this.selectedSortBy ? this.selectedSortBy : "",
        sort_order: this.selectedSortOrder ? this.selectedSortOrder : "",
        jenis_name: this.selectedJenis ? this.selectedJenis.join(",") : "",
        kategori_name: this.selectedKategori
          ? this.selectedKategori.join(",")
          : "",
        search: this.searchKey,
      };

      const apiUrl = `https://staging-api.jaja.id/product/get-product?${new URLSearchParams(
        params
      )}`;
      const headers = {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
      };

      try {
        console.log("Fetching from API:", apiUrl);
        const response = await fetch(apiUrl, { headers });
        const data = await response.json();

        console.log("API Response:", data);

        if (data.data) {
          this.searchResults = data.data;
          localStorage.setItem("searchResults", JSON.stringify(data.data));
          localStorage.setItem(
            "selectedMerek",
            JSON.stringify(this.selectedMerek)
          );
          localStorage.setItem(
            "selectedTransmisi",
            JSON.stringify(this.selectedTransmisi)
          );
          localStorage.setItem("selectedBbm", JSON.stringify(this.selectedBbm));

          const currentParams = this.$route.query;
          const sameParams = Object.keys(params).every(
            (key) => params[key] === currentParams[key]
          );

          if (!sameParams) {
            this.$router.push({
              path: "/search",
              query: params,
            });

            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        } else {
          console.error("Unexpected API response structure:", data);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    },
  },
  components: {
    navigation,
    foote,
  },
};
</script>
