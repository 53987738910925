<template>
  <div style="background-color: #f8f8f8">
    <!-- Tampilan Navbar -->
    <navigation />
    <div class="contoh-elemen">
      <div
        style="padding: 5%; background: var(--color-neutral-neutral-00, #fff)"
        class="row"
      >
        <div class="d-none d-lg-block col-lg-6">
          <div class="car-single-top" style="position: relative; z-index: 1">
            <v-chip
              class="ma-2"
              closable
              color="#DEF3FF"
              text-color="#207ACE"
              label
              style="font-family: Poppins"
            >
              {{ carDetail[0].merek }}
            </v-chip>
            <router-link :to="'/'">
              <img
                class="d-lg-none mt-1"
                src="../assets/img/homepage/batch.png"
                style="width: 100px; height: 31px"
                alt=""
            /></router-link>
            <div class="judul">
              <h3
                style="color: black; font-family: Poppins"
                class="car-single-title"
              >
                {{ carDetail[0].name }}
              </h3>
            </div>

            <div
              style="font-size: 16px; font-family: Poppins"
              class="d-none d-lg-block car-single-meta"
            >
              <li>
                <i class="fa fa-car"></i> Jenis:
                {{ carDetail[0].jenis_name }}
              </li>
              <li>
                <i class="far fa-clock"></i> Dibuat:
                {{ carDetail[0].date_created }}
              </li>
              <li>
                <i class="far fa-eye"></i> Dilihat:
                {{ carDetail[0].viewed }}
              </li>
            </div>
          </div>

          <div class="image-haha">
            <img
              :src="carDetail[0].images[0].imagePath"
              class="car-image"
              alt=""
            />
          </div>
        </div>
        <div class="col-lg-6">
          <div
            style="
              border-radius: 20px;

              padding: 20px;
            "
          >
            <p
              style="
                color: black;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.32px;
                color: #207ace;
                font-family: Poppins;
              "
            >
              Pengajuan Test Drive
            </p>
            <span
              style="
                color: black;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.27px;
                font-family: Poppins;
              "
              >Silahkan isi form data anda untuk dapat melakukan test drive unit
              impian anda bersama kami</span
            >

            <hr />

            <!-- <span> {{ formatPriceAsRupiah(selectedTenorData) }}</span> -->
            <br />
            <form @submit.prevent="submitForm">
              <v-row>
                <v-col cols="12" md="12">
                  <div class="form-group">
                    <label style="font-family: Poppins" class="label-form"
                      >Model untuk Test Drive</label
                    >
                    <!-- <v-select
                      class="mt-3"
                      style="font-family: Poppins !important"
                      v-model="selectedMerek"
                      :options="merekOptions"
                      label="name"
                      value="id"
                      placeholder="Pilih Merk"
                      multiple
                    ></v-select> -->
                    <input
                      type="text"
                      class="mt-3 form-control"
                      style="font-family: Poppins !important"
                      v-model="selectedCarName"
                      disabled
                    />
                  </div>
                </v-col>
              </v-row>
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label style="font-family: Poppins" class="label-form"
                      >Nama Depan</label
                    >
                    <input
                      v-model="firstname"
                      type="text"
                      class="mt-3 form-control"
                      placeholder="Nama Depan"
                      style="font-family: Poppins; height: 40px"
                      required
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label style="font-family: Poppins" class="label-form"
                      >Nama Belakang</label
                    >
                    <input
                      style="font-family: Poppins; height: 40px"
                      type="text"
                      class="mt-3 form-control"
                      placeholder="Nama Belakang"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label style="font-family: Poppins" class="label-form"
                      >Provinsi</label
                    >
                    <el-select
                      class="mt-3"
                      style="font-family: Poppins !important; width: 100%"
                      v-model="selectedProvince"
                      multiple
                      placeholder="Pilih Provinsi"
                      filterable
                      @change="
                        fetchCities(
                          selectedProvince
                            .map((item) => item.id_provinsi)
                            .join(',')
                        )
                      "
                      required
                    >
                      <el-option
                        v-for="province in provincesOptions"
                        :key="province.id_provinsi"
                        :label="province.nama_provinsi"
                        :value="province.id_provinsi"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label style="font-family: Poppins" class="label-form"
                      >Kota</label
                    >
                    <el-select
                      class="mt-3"
                      style="
                        font-family: Poppins !important;
                        width: 100%;
                     
                      "
                      v-model="selectedCity"
                      multiple
                      placeholder="Pilih Kota"
                      filterable
                      required
                    >
                      <el-option
                      style="height: 40px;"
                        v-for="city in cityOptions"
                        :key="city.id_kota"
                        :label="city.nama_kota"
                        :value="city.id_kota"
                      ></el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label style="font-family: Poppins" class="label-form"
                      >Email</label
                    >
                    <input
                      v-model="email"
                      style="height: 40px; font-family: Poppins"
                      type="email"
                      class="mt-3 form-control"
                      placeholder="Email Anda"
                      required
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="form-group">
                    <label style="font-family: Poppins" class="label-form"
                      >Nomor Telepon</label
                    >
                    <div class="input-group mb-3">
                      <div class="mt-3 input-group-prepend">
                        <span
                          style="height: 40px"
                          class="input-group-text"
                          id="basic-addon1"
                          >+62</span
                        >
                      </div>
                      <input
                        type="tel"
                        style="height: 4 0px; font-family: Poppins"
                        class="mt-3 form-control"
                        v-model="phone"
                        placeholder="Nomor Telepon Anda"
                        aria-describedby="basic-addon1"
                        id="inputNumber"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div>
                <li class="mt-1 text-ketentuan">
                  1. Mentaati dan memperhatikan instruksi dari Panitia dan
                  Marshal yang bertugas saat persiapan maupun saat mengendarai
                  kendaraan.
                </li>
                <li class="mt-1 text-ketentuan">
                  2. Mengikuti peraturan, ketentuan dan keputusan yang
                  dikeluarkan oleh penyelenggara event
                </li>
                <li class="mt-1 text-ketentuan">
                  3. Peserta wajib mendatangi booth JAJA AUTO dan BYD untuk
                  konfirmasi sebelum melakukan Test Drive dan
                  didampingi oleh Sales.
                </li>
                <br />
                <input
                  class="mr-5"
                  type="checkbox"
                  id="additionalCheckbox"
                  v-model="additionalCheckboxValue"
                />
                <label class="" for="additionalCheckbox"
                  ><b> Saya memiliki Sim A asli dan masih berlaku</b></label
                >

                <br />
                <input
                  class="mr-5"
                  type="checkbox"
                  id="agreeTerms"
                  v-model="agreeTerms"
                  required
                />
                <label class="" for="agreeTerms"
                  ><b>Saya menyetujui syarat dan ketentuan</b></label
                >
              </div>

              <v-row>
                <div class="mt-4 form-group">
                  <button
                    type="submit"
                    style="height: 50px; font-family: Poppins"
                    class="btn-ajukan-angsuran"
                    :disabled="submitting"
                  >
                    {{
                      submitting
                        ? "Sedang dalam proses..."
                        : "Ajukan Test Drive"
                    }}
                    <!-- Mengubah teks tombol sesuai dengan status submitting -->
                  </button>
                </div>
              </v-row>
            </form>
          </div>
        </div>
      </div>
    </div>
    <foote />
  </div>
</template>
    <script>
import navigation from "../components/backup";
import foote from "../components/Footer";
import axios from "axios";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css"; // Import the CSS file for vue-select
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
export default {
  name: "ProsesTunai",
  components: {
    navigation, //Navbar
    foote, // Footer
    vSelect,
    "el-select": ElementUI.Select,
    "el-option": ElementUI.Option,
  },
  data() {
    return {
      additionalCheckboxValue: false,
      submitting: false,
      agreeTerms: false,
      selectedMerek: [],
      firstname: "",
      lastname: "",
      selectedProvince: [],
      selectedCity: [],
      email: "",
      phone: "",
      merekOptions: [], // Assign this with your available options
      provincesOptions: [], // Assign this with your available options
      cityOptions: [], // Assign this with your available options
      carId: null,
      carName: null,
      selectedCarId: null,
      selectedCarName: null,
      carDetail: null,
    };
  },
  mounted() {
    this.fetchProvinces();
    this.fetchMereks();
    this.carDetail = JSON.parse(localStorage.getItem("carDetail"));
    // Mengambil data dari localStorage saat komponen dimuat
    this.carId = localStorage.getItem("carId");
    this.carName = localStorage.getItem("carName");
    this.selectedAllProduk = this.allProduk; // Set nilai awal input
    this.selectedCarId = this.carId; // Set nilai awal input
    this.selectedCarName = this.carName; // Set nilai awal input
    // Menghapus data dari localStorage setelah diambil
    // localStorage.removeItem("carId");
    // localStorage.removeItem("carName");
  },
  watch: {
    // Watch for changes in selectedProvince and fetch cities accordingly
    selectedProvince: {
      handler: function (selectedProvince) {
        // If selectedProvince has changed and is not empty, fetch cities
        if (selectedProvince !== null && selectedProvince.id_provinsi) {
          this.fetchCities(selectedProvince.id_provinsi);
        }
      },
      // Deep watch is not necessary anymore
      deep: false,
    },
  },
  methods: {
    async submitForm() {
      try {
        if (!this.additionalCheckboxValue) {
          // Jika checkbox tambahan tidak dicentang, tampilkan pesan peringatan
          Swal.fire({
            icon: "warning",
            title: "Peringatan",
            text: "Harap setujui syarat memiliki Sim A asli, sah dan masih berlaku.",
            confirmButtonText: "OK",
            didOpen: () => {
              const confirmButton = document.querySelector(".swal2-confirm");
              if (confirmButton) {
                confirmButton.style.height = "50px"; // Atur tinggi sesuai keinginan Anda
                confirmButton.style.backgroundColor = "#07528E"; // Atur warna latar belakang sesuai keinginan Anda
                confirmButton.style.color = "white"; // Atur warna teks sesuai keinginan Anda
              }
            },
          });
          return; // Hentikan proses lebih lanjut
        }
        if (!this.agreeTerms) {
          // Jika persetujuan tidak dicentang, tampilkan pesan peringatan
          Swal.fire({
            icon: "warning",
            title: "Peringatan",
            text: "Harap setujui syarat dan ketentuan sebelum melanjutkan.",
            confirmButtonText: "OK",
            didOpen: () => {
              const confirmButton = document.querySelector(".swal2-confirm");
              if (confirmButton) {
                confirmButton.style.height = "50px"; // Atur tinggi sesuai keinginan Anda
                confirmButton.style.backgroundColor = "#07528E"; // Atur warna latar belakang sesuai keinginan Anda
                confirmButton.style.color = "white"; // Atur warna teks sesuai keinginan Anda
              }
            },
          });
          return; // Hentikan proses lebih lanjut
        }
        this.submitting = true;
        const requestBody = {
          //   id_produk: this.selectedMerek
          //     ? this.selectedMerek.map((item) => item.id).join(",")
          //     : "",
          id_produk: this.carId,
          firstname: this.firstname,
          lastname: this.lastname,
          province: this.selectedProvince
            ? this.selectedProvince.nama_provinsi
            : "",
          city: this.selectedCity ? this.selectedCity.nama_kota : "",
          email: this.email,
          phone: "62" + this.phone,
        };

        const response = await axios.post(
          "https://api.jaja.id/jauto/order/submit_testdrive",
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
            },
          }
        );

        if (response.data.success) {
          // Jika pengajuan berhasil
          Swal.fire({
            icon: "success",
            title: "Pengajuan Dikirim!",
            text: "Data anda telah dikirim, Mohon tunggu konfirmasi dari kami",
            confirmButtonText: "OK",
            didOpen: () => {
              const confirmButton = document.querySelector(".swal2-confirm");
              if (confirmButton) {
                confirmButton.style.height = "50px"; // Atur tinggi sesuai keinginan Anda
                confirmButton.style.backgroundColor = "#07528E"; // Atur warna latar belakang sesuai keinginan Anda
                confirmButton.style.color = "white"; // Atur warna teks sesuai keinginan Anda
              }
            },
          }).then(() => {
            this.$router.push({ path: "/" });
          });
          // Clear form fields after successful submission
          this.selectedMerek = null;
          this.firstname = "";
          this.lastname = "";
          this.selectedProvince = null;
          this.selectedCity = null;
          this.email = "";
          this.phone = "";
        } else {
          // Jika pengajuan gagal
          throw new Error(response.data.message);
        }
      } catch (error) {
        // Menangani kesalahan
        console.error("Failed to submit form:", error);
        Swal.fire({
          icon: "error",
          title: "Gagal Mengajukan Test Drive",
          text: error.message,
        });
      } finally {
        // Setelah selesai, kembalikan submitting ke false
        this.submitting = false;
      }
    },

    async fetchMereks() {
      const apiUrl = "https://api.jaja.id/jauto/order/option_produk";
      const headers = {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
      };

      try {
        const response = await fetch(apiUrl, { headers });
        const data = await response.json();

        if (data.success) {
          this.merekOptions = data.data;
        } else {
          console.error("Gagal mengambil data dari API");
        }
      } catch (error) {
        console.error("Terjadi kesalahan dalam mengambil data:", error);
      }
    },
    async fetchProvinces() {
      const apiUrl = "https://api.jaja.id/jauto/order/option_province";
      const headers = {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
      };

      try {
        const response = await fetch(apiUrl, { headers });
        const data = await response.json();

        if (data.success) {
          this.provincesOptions = data.data;
        } else {
          console.error("Gagal mengambil data dari API");
        }
      } catch (error) {
        console.error("Terjadi kesalahan dalam mengambil data:", error);
      }
    },
    async fetchCities(selectedProvinceId) {
      const apiUrl = `https://api.jaja.id/jauto/order/option_city?id_provinsi=${selectedProvinceId}`;
      const headers = {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
      };

      try {
        const response = await fetch(apiUrl, { headers });
        const data = await response.json();

        if (data.success) {
          this.cityOptions = data.data;
        } else {
          console.error("Gagal mengambil data dari API");
        }
      } catch (error) {
        console.error("Terjadi kesalahan dalam mengambil data:", error);
      }
    },
  },
};
</script>
    <style scoped>
@media only screen and (max-width: 440px) {
  .contoh-elemen {
    padding-top: 0%;
  }
}
@media only screen and (min-width: 1275px) {
  .contoh-elemen {
    padding-top: 162px;
  }
}
.btn-ajukan-angsuran {
  font-size: 16px;
  color: var(--color-white);
  padding: 12px 20px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  border-radius: 13px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;
  background: var(--theme-color);
  box-shadow: var(--box-shadow);
  z-index: 1;
  width: 100%;
}
.btn-ajukan-angsuran:hover {
  background: #fbab44;
  color: black;
}
.card-tenor {
  border-radius: 10px;
  border: 1px solid var(--color-blue-blue-06, #207ace);
  background: var(--color-neutral-neutral-00, #fff);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-judul-angsuran {
  color: var(--color-neutral-neutral-08, #1f1c1c);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
}
.title-angsuran {
  color: var(--color-blue-blue-06, #207ace);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
}
.ca {
  display: flex;
  padding: 24px 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  background: #e9f7ff;
}
.cb {
  display: flex;
  padding: 24px 12px;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: stretch;
  background: #c3eaff;
}
.text-card {
  color: var(--color-blue-blue-06, #207ace);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
}
.ca1 {
  display: flex;
  width: 100%;
  padding: 24px 12px;
  justify-content: center;
  align-items: center;

  align-self: stretch;
  border-radius: 10px 10px 0px 0px;
}
.ta1 {
  color: var(--color-blue-blue-05, #207ace);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
}
.ta2 {
  color: var(--color-blue-blue-06, #207ace);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
}
.text-ketentuan {
  color: var(--color-neutral-neutral-06, #706d6d);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-jdl-otr {
  color: var(--color-neutral-neutral-06, #706d6d);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}
.label-form {
  color: var(--color-neutral-neutral-08, #1f1c1c);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}
.button {
  /* margin-right: 12px; */
  font-size: 16px;
  color: black;
  padding: 12px 20px;
  transition: all 0.5s;
  text-transform: capitalize;
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;
  background: var(--color-yellow-yellow-05, #fbab44);
  box-shadow: var(--box-shadow);
  z-index: 1;
  width: 100%;
}
.button:hover {
  background: #207ace; /* Change the background color on hover */
  color: white; /* Change the text color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a box shadow on hover */
}
.image-haha {
  position: relative;
  margin-top: 0px;
  z-index: 0;
}
</style>
    