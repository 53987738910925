<template>
  <div class="home-3">
    <navigation />
    <div style="margin-top: 6%" class="container">
      <div class="car-area py-60">
        <div class="container">
          <div class="d-flex justify-content-center mt-5">
            <h2
              style="
                font-weight: 700;
                text-transform: capitalize;
                font-size: 32px;
                color: var(--color-dark);
                margin-bottom: 0;
                font-family: Poppins;
              "
            >
              Kategori
              <span style="color: #1f1c1c; font-family: Poppins">Mobil</span>
            </h2>
          </div>
          <br />
          <div class="container">
            <div class="d-flex justify-content-center">
              <div class="row">
                <div
                  v-for="kategori in kategoriData"
                  :key="kategori.id"
                  :class="{
                    'selected-item':
                      hoveredKategoriId === kategori.id ||
                      selectedKategoriId === kategori.id,
                  }"
                  class="col-lg"
                  @mouseover="selectKategori(kategori)"
                  @mouseleave="hoveredKategoriId = null"
                >
                  <div data-wow-delay=".25s">
                    <div class="car-gambar">
                      <img
                        :src="kategori.image"
                        :alt="kategori.kategori_name"
                        style="
                          max-width: 120px;
                          height: auto;
                          border-radius: 10px;
                        "
                      />

                      <div class="car-btns">
                        <a href="#"><i class="far fa-heart"></i></a>
                        <a href="#"><i class="far fa-arrows-repeat"></i></a>
                      </div>
                    </div>
                    <p
                      class="car-gambar"
                      style="font-size: small; font-family: Poppins"
                    >
                      {{ kategori.kategori_name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="car-area py-60" v-if="showSelectedMerkData">
        <hr />
        <div class="container">
          <div class="d-flex justify-content-center mt-5">
            <h2
              style="
                font-weight: 700;
                text-transform: capitalize;
                font-size: 32px;
                color: var(--color-dark);
                margin-bottom: 0;
                font-family: Poppins;
              "
            >
              Merk
              <span style="color: #1f1c1c; font-family: Poppins">Mobil</span>
            </h2>
          </div>
          <br />
          <div class="container">
            <div class="d-flex justify-content-center">
              <div class="row">
                <div
                  v-for="brand in selectedMerkData"
                  :key="brand.id_merek"
                  class="col-lg-2 mb-4 car-gambar-brand"
                  @click="selectAndSearchBrand(brand)"
                >
                  <div data-wow-delay=".25s">
                    <div
                      style="
                        max-width: 120px;
                        height: 120px;
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                      "
                    >
                      <img
                        :src="brand.logo"
                        :alt="brand.nama_merek"
                        style="
                          max-width: 100%;
                          max-height: 100%;
                          object-fit: contain;
                          border-radius: 0;
                          margin: auto;
                          display: block;
                        "
                      />
                      <div class="car-btns">
                        <a href="#"><i class="far fa-heart"></i></a>
                        <a href="#"><i class="far fa-arrows-repeat"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a href="#" id="scroll-top"><i class="far fa-arrow-up"></i></a>
    <!-- Tampilan Footer -->
    <foote />
  </div>
</template>
<script>
import axios from "axios";
import navigation from "../components/backup";
import foote from "../components/Footer";
export default {
  data() {
    return {
      kategoriData: [],
      hoveredKategoriId: null,
      selectedKategoriId: null, // Menyimpan ID kategori yang dipilih
      selectedMerekId: null, // Menyimpan ID merek yang dipilih
    };
  },
  components: {
    navigation,
    foote,
  },
  mounted() {
    // this.fetchKategoriData();
  },
  methods: {
    selectKategori(kategori) {
      this.selectedKategoriId = kategori.id; // Setel ID kategori yang dipilih
      this.selectedMerkData = kategori.merek;
      this.showSelectedMerkData = true;
    },
    selectAndSearchBrand(brand) {
      console.log("Selected Brand:", brand);
      this.selectedMerekId = brand.id_merek; // Setel ID merek yang dipilih
      this.selectedMerek = [
        {
          value: brand.nama_merek,
          label: brand.nama_merek,
          slug: brand.slug_merek,
        },
      ];
      this.searchCars();
    },
    async searchCars() {
      console.log("Search Cars Called");
      // Inisialisasi parameter yang akan dikirim ke API
      const params = {
        merek: this.selectedMerek
          ? this.selectedMerek.map((item) => item.value).join(",")
          : "",
        transmisi: this.selectedTransmisi
          ? this.selectedTransmisi.map((item) => item.value).join(",")
          : "",
        bbm: this.selectedBbm ? this.selectedBbm.value : "",
        seat: this.selectedSeat ? this.selectedSeat.value : "",
        sort_by: this.selectedSortBy ? this.selectedSortBy.value : "",
        sort_order: this.selectedSortOrder ? this.selectedSortOrder.value : "",
        jenis_name: this.selectedJenis
          ? this.selectedJenis.map((item) => item.label).join(",")
          : "",
        kategori_name: this.selectedKategori
          ? this.selectedKategori.map((item) => item.label).join(",")
          : "",
        slug_merek: this.selectedMerek ? this.selectedMerek[0].slug : "",
        search: this.searchKey || "", // Menambahkan kondisi untuk menghindari undefined
        //length: 100,
      };

      // Buat URL API dengan parameter yang telah dicek
      const apiUrl = `https://api.jaja.id/jauto/produk/get_search_result?${new URLSearchParams(
        params
      )}`;

      const headers = {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
      };

      try {
        const response = await fetch(apiUrl, { headers });
        const data = await response.json();

        if (data.success) {
          this.searchResults = data.data;
          console.log("Search Results:", this.searchResults); // Tambahkan log ini untuk memeriksa hasil pencarian
          // Simpan hasil pencarian ke dalam Local Storage
          localStorage.setItem(
            "searchResults",
            JSON.stringify(this.searchResults)
          );
          localStorage.setItem(
            "selectedMerek",
            JSON.stringify(this.selectedMerek)
          );

          // Redirect ke halaman /search
          this.$router.push({
            path: "/search",
            query: params,
          });
        } else {
          console.error("Gagal mengambil hasil pencarian dari API");
        }
      } catch (error) {
        console.error(
          "Terjadi kesalahan dalam mengambil hasil pencarian:",
          error
        );
      }
    },

    // async fetchKategoriData() {
    //   const apiUrl = "https://api.jaja.id/jauto/produk/get_kategori";
    //   const headers = {
    //     Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
    //   };

    //   try {
    //     const response = await fetch(apiUrl, { headers });
    //     const data = await response.json();

    //     if (data.success) {
    //       this.kategoriData = data.data;
    //     } else {
    //       console.error("Gagal mengambil data kategori dari API");
    //     }
    //   } catch (error) {
    //     console.error(
    //       "Terjadi kesalahan dalam mengambil data kategori:",
    //       error
    //     );
    //   }
    // },
  },
};
</script>

<style scoped>
.car-gambar {
  display: flex;
  justify-content: center; /* Memposisikan gambar secara horizontal di tengah */
  align-items: center; /* Memposisikan gambar secara vertikal di tengah */
}

.car-gambar-brand:hover {
  padding: 5px;
  border: 1px solid #1f1c1c; /* Warna dan ketebalan border dapat disesuaikan */
  border-radius: 13px;
  display: flex;
  justify-content: center; /* Memposisikan gambar secara horizontal di tengah */
  align-items: center; /* Memposisikan gambar secara vertikal di tengah */
  /* width: 10px; */
  /* height: 100px; */
}

.selected-item {
  border: 1px solid #1f1c1c;
  border-radius: 13px;
}
</style>