var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-area py-120"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-md-5 mx-auto"},[_c('div',{staticClass:"login-form"},[_vm._m(0),_vm._m(1),_c('span',[_vm._v("Isikan dengan lengkap")]),_c('hr'),_c('form',{attrs:{"id":"registerForm"},on:{"submit":_vm.register}},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"login-footer"},[_c('p',[_vm._v(" Already have an account? "),_c('router-link',{attrs:{"to":"/loginaca"}},[_vm._v("Login.")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-header"},[_c('img',{attrs:{"src":require("../assets/img/logo/logoauto.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-subtitle-1 text-medium-emphasis"},[_c('b',[_vm._v("Daftar")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Full Name")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"fullName","placeholder":"Your Name"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email Address")]),_c('input',{staticClass:"form-control",attrs:{"type":"email","id":"email","placeholder":"Your Email"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Phone Number")]),_c('input',{staticClass:"form-control",attrs:{"type":"number","id":"phoneNumber","placeholder":"Your Phone Number"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Password")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","id":"password","placeholder":"Your Password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('button',{staticStyle:{"font-size":"16px","color":"var(--color-white)","padding":"12px 20px","transition":"all 0.5s","text-transform":"capitalize","position":"relative","width":"100%","border-radius":"13px","font-weight":"500","cursor":"pointer","text-align":"center","overflow":"hidden","border":"none","background":"var(--theme-color)","box-shadow":"var(--box-shadow)","z-index":"1"},attrs:{"type":"submit","id":"registerButton"}},[_c('i',{staticClass:"far fa-paper-plane"}),_vm._v(" Register ")])])
}]

export { render, staticRenderFns }