var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-3"},[_c('navigation'),_c('div',{staticClass:"container",staticStyle:{"margin-top":"6%"}},[_c('div',{staticClass:"car-area py-60"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('br'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"row"},_vm._l((_vm.kategoriData),function(kategori){return _c('div',{key:kategori.id,staticClass:"col-lg",class:{
                  'selected-item':
                    _vm.hoveredKategoriId === kategori.id ||
                    _vm.selectedKategoriId === kategori.id,
                },on:{"mouseover":function($event){return _vm.selectKategori(kategori)},"mouseleave":function($event){_vm.hoveredKategoriId = null}}},[_c('div',{attrs:{"data-wow-delay":".25s"}},[_c('div',{staticClass:"car-gambar"},[_c('img',{staticStyle:{"max-width":"120px","height":"auto","border-radius":"10px"},attrs:{"src":kategori.image,"alt":kategori.kategori_name}}),_vm._m(1,true)]),_c('p',{staticClass:"car-gambar",staticStyle:{"font-size":"small","font-family":"Poppins"}},[_vm._v(" "+_vm._s(kategori.kategori_name)+" ")])])])}),0)])])])]),(_vm.showSelectedMerkData)?_c('div',{staticClass:"car-area py-60"},[_c('hr'),_c('div',{staticClass:"container"},[_vm._m(2),_c('br'),_c('div',{staticClass:"container"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"row"},_vm._l((_vm.selectedMerkData),function(brand){return _c('div',{key:brand.id_merek,staticClass:"col-lg-2 mb-4 car-gambar-brand",on:{"click":function($event){return _vm.selectAndSearchBrand(brand)}}},[_c('div',{attrs:{"data-wow-delay":".25s"}},[_c('div',{staticStyle:{"max-width":"120px","height":"120px","overflow":"hidden","display":"flex","flex-direction":"column","align-items":"center","text-align":"center"}},[_c('img',{staticStyle:{"max-width":"100%","max-height":"100%","object-fit":"contain","border-radius":"0","margin":"auto","display":"block"},attrs:{"src":brand.logo,"alt":brand.nama_merek}}),_vm._m(3,true)])])])}),0)])])])]):_vm._e()]),_vm._m(4),_c('foote')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('h2',{staticStyle:{"font-weight":"700","text-transform":"capitalize","font-size":"32px","color":"var(--color-dark)","margin-bottom":"0","font-family":"Poppins"}},[_vm._v(" Kategori "),_c('span',{staticStyle:{"color":"#1f1c1c","font-family":"Poppins"}},[_vm._v("Mobil")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"car-btns"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"far fa-heart"})]),_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"far fa-arrows-repeat"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('h2',{staticStyle:{"font-weight":"700","text-transform":"capitalize","font-size":"32px","color":"var(--color-dark)","margin-bottom":"0","font-family":"Poppins"}},[_vm._v(" Merk "),_c('span',{staticStyle:{"color":"#1f1c1c","font-family":"Poppins"}},[_vm._v("Mobil")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"car-btns"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"far fa-heart"})]),_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"far fa-arrows-repeat"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"#","id":"scroll-top"}},[_c('i',{staticClass:"far fa-arrow-up"})])
}]

export { render, staticRenderFns }